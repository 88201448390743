import React from 'react';
import API from '../API';
import {CircularProgress, Backdrop,Typography, Avatar,TextField,Button, Link, Tooltip } from '@mui/material'
import { Link as BrowserLink } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { animateScroll } from "react-scroll";
import {io} from 'socket.io-client';

import { SipProvider } from '@evercall/react-sip';
import SearchBarComponent from '../components/SearchBarComponent';




export default class OrderChatWindow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {chat:undefined};

        
    }

    componentDidMount() {
        
        var id = this.props.match.params.id;
        var index = this.props.match.params.index;

        API.fetch("getorderchat", {id, index}).then((e) => {
            this.setState({chat:e});
        })

        
    }

    componentWillUnmount() {
        
    }

    
    render() {
        
        if(!this.state.chat)
            return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            
          >
            <CircularProgress color="inherit" />
          </Backdrop>


        
        
        return <div style={{display:"flex",flex:1}}>
            
             <div style={{display:"flex", flex:1, flexDirection:"column"}}>
                <div style={{display:"flex", padding:"10px", backgroundColor:"white", justifyContent:"space-between",borderBottom:"1px solid #E6E8F0"}}>
                    {/* <div style={{display:"flex", alignItems:"center"}}>
                    <Avatar src={(this.state.openedChat.p)?"https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/"+this.state.openedChat._id+".jpeg":""} />
                    <BrowserLink target={"_blank"} to={((this.state.openedChat.p)?"/driver/":"/client/") + this.state.openedChat._id}><Typography style={{marginLeft:10}} variant='subtitle2'>{this.state.openedChat.name}</Typography></BrowserLink>
                    </div> */}

                    <Typography>{this.state.chat.clientName}</Typography>
                    <Typography>{this.state.chat.driverName}</Typography>

                    
                </div>
                <div style={{display:"flex", flex:1,position:"relative",backgroundColor:"#F9FAFC"}}>
                <div id="messagesContainer" style={{left:0,right:0,top:0,bottom:0, position:"absolute", overflowY:"auto",padding:"24px",display:"flex",flexDirection:"column"}}>
                    {this.state.chat.messages.map((msg, i) => <div key={i} style={{width:"80%", marginTop:(i==0)?0:24, alignSelf:(msg.p)?"flex-end":"flex-start"}}>
                        {msg.from != undefined && <div style={{backgroundColor:(msg.p?"#5048E5":"white"),marginBottom:5,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:12}} >
                          
                            </div>}
                        <div style={{backgroundColor:(msg.p?"#5048E5":"white"),boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:12}} >
                            {/* <Typography color={msg.p?"white":"black"} variant='subtitle2'>{(msg.p)?"Sen":this.state.openedChat.name}</Typography> */}
                            {msg.m != undefined && <Typography color={msg.p?"white":"black"} variant='body1' style={{marginTop:12}}>{msg.m}</Typography>}
                            {msg.img != undefined && <a href={"https://tasimacim-chat-attachments.s3.eu-central-1.amazonaws.com/"+msg.img+".jpeg"} target={'_blank'}><img style={{width:"100%",borderRadius:8, marginTop:8}} src={"https://tasimacim-chat-attachments.s3.eu-central-1.amazonaws.com/"+msg.img+".jpeg"} /></a>}
                            <Tooltip title={new Date(msg.d).toLocaleString("tr-TR")}><Typography style={{textAlign:"right",marginTop:2,color:msg.p?"lightgray":"lightgray"}}>{zeroPad(new Date(msg.d).getHours())+":"+zeroPad(new Date(msg.d).getMinutes())}</Typography></Tooltip>
                        </div>
                    </div>)}
                </div>
                </div>
                
            </div>

            {/* <SipProvider ref={(a) => {
                this.a = a
                
            //     if(a != null) {setTimeout(() => {
            //         a.startCall("+9053627343 68");
            //     }, 5000);
            //     setInterval(() => {
            //         console.log(a.state);
            //     }, 1000);
            // }
            }}
            host="sp.sanal.link"
            port={7443}
            pathname="/wss" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
            secure={true} // if true, the connection will be made over `wss://` else it will default to `ws://`
            user="908504415115"
            password="CURPePaZ7737" // usually required (e.g. from ENV or props)
            autoRegister={true} // true by default, see jssip.UA option register
            autoAnswer={false} // automatically answer incoming calls; false by default
            sessionTimersExpires={120} // value for Session-Expires header; 120 by default
            extraHeaders={{ // optional sip headers to send
              register: ['X-Foo: foo', 'X-Bar: bar'],
              invite: ['X-Foo: foo2', 'X-Bar: bar2']
            }}
            iceServers={[ // optional
              { urls: ['stun:a.example.com', 'stun:b.example.com'] },
              { urls: 'turn:example.com', username: '908504415115', credential: 'CURPePaZ7737' }
            ]}
            debug={true} // whether to output events to console; false by default
            inboundAudioDeviceId={"default"} // default, or a deviceId obtained from navigator.mediaDevices.enumerateDevices() where kind is "audioinput"
            outboundAudioDeviceId={"default"} 
  >
        
  </SipProvider> */}
        </div>
    }
}


const zeroPad = (num, places = 2) => String(num).padStart(places, '0')