import React from 'react';
import { Typography, Button, Avatar, Menu,TextField, Select, MenuItem,CircularProgress, Backdrop, Checkbox, FormGroup, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SearchBarComponent from '../components/SearchBarComponent';
const types = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet","Esnek"];
export default class NotificationCenter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {receiverType: undefined, filterType: undefined, filters: {}, openAction: 0,selectedUsers: [], data: {},title: {en: "", tr: ""}, body: {en: "", tr: ""},audienceSize: [0,0],approveSend: false, backdropVisible: false, cityFilter: [6,34,35]};

        
       

        
    }

    componentDidMount() {
        
    }
    render() {
        

        
        return <div style={{display:"flex", justifyContent:"center"}}>

            <div style={{maxWidth:900, width:"100%"}}>
            


            <Typography variant='h4' style={{marginBottom: 30}}>Bildirim Merkezi</Typography>

            <Badge>
                <Typography variant='h6'>Kitle Ayarları</Typography>
                <FormControl style={{marginTop:20}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Alıcı Tipi</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={this.state.receiverType}
        onChange={(a) => this.setState({receiverType: a.target.value, selectedUsers: []})}
      >
        <FormControlLabel value={0} control={<Radio />} label="Müşteri" />
        <FormControlLabel value={1} control={<Radio />} label="Sürücü" />
        
        
      </RadioGroup>
    </FormControl>


    <FormControl style={{marginTop:20}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Kitle Tipi</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={this.state.filterType}
        onChange={(a) => this.setState({filterType: a.target.value})}
      >
        <FormControlLabel value={0} control={<Radio />} label="Filtrele" />
        <FormControlLabel value={1} control={<Radio />} label="Manuel" />
        
        
      </RadioGroup>
    </FormControl>

        {this.state.filterType == 0 && <Typography style={{marginTop:20}}>Filtreler</Typography>}
    {this.state.receiverType == 1 && this.state.filterType == 0 && <FormGroup style={{marginTop:5}}>
  <FormControlLabel control={<Checkbox onClick={() => {
    
    if(this.state.filters.vehicles == undefined) {
        this.state.filters.vehicles = {$in: [0,1,2,3,4,5]};
    } else {
        delete this.state.filters.vehicles;
    }
    console.log(this.state.filters.vehicles == undefined);

    this.forceUpdate();
  }} checked={this.state.filters.vehicles != undefined} />} label="Araç Tipine Göre Filtrele" />
  
</FormGroup>}

{this.state.filterType == 0 && this.state.filters.vehicles != undefined && types.map((x, i) => <FormControlLabel sx={{padding: "2px"}} control={<Checkbox checked={this.state.filters.vehicles.$in.includes(i)} onChange={(a, val) => {
                    
                    var indx = this.state.filters.vehicles.$in.indexOf(i);
                    if(indx == -1) {
                        this.state.filters.vehicles.$in.push(i);
                    } else {
                        this.state.filters.vehicles.$in.splice(indx,1);
                    }

                    this.forceUpdate();

                    
                  }} sx={{padding: "2px"}} key={i} />} label={x}  />)}

{this.state.filterType == 1 && this.state.receiverType != undefined && <>

<Typography style={{marginTop:20,marginBottom:10}}>Üye Seçimi</Typography>
<div style={{marginBottom:20,display:"flex",gap:10,flexWrap:"wrap"}}>{this.state.selectedUsers.map((x, i) => <Chip key={i} label={x.n} onDelete={() => {
    this.state.selectedUsers.splice(i,1);
    this.forceUpdate();
}} />)}</div>
<SearchBarComponent ref={a => this.manualSearch = a} onValueSelected={(v) => {
    if(!v) return;

    if(!this.state.selectedUsers.find((s) => s._id == v._id)) {
    this.state.selectedUsers.push({n: v.label.split(v.type==1?",":":")[1],_id: v._id})
    this.forceUpdate();
    }
    //setTimeout(() => {
        this.manualSearch.setSearchValue("");
    
    
}} search={({drivers: this.state.receiverType == 1, clients: this.state.receiverType == 0, orders: false, applications: false})} />
</>}


{this.state.filterType == 0 && this.state.receiverType == 1 && <FormControl style={{marginTop:20}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Tıklama Aksiyonu</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={this.state.openAction}
        onChange={(a) => this.setState({openAction: a.target.value, data: {}})}
      >
        <FormControlLabel value={0} control={<Radio />} label="Hiçbir şey" />
        <FormControlLabel value={1} control={<Radio />} label="Siparişi Aç" />
        
        
      </RadioGroup>
    </FormControl>}

    


    {this.state.receiverType ==0 && <FormControl style={{marginTop:20}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Tıklama Aksiyonu</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={this.state.openAction}
        onChange={(a) => this.setState({openAction: a.target.value, data: a.target.value == 1?{review: 1}:{}})}
      >
        <FormControlLabel value={0} control={<Radio />} label="Hiçbir şey" />
        <FormControlLabel value={1} control={<Radio />} label="Yorum Yap" />
        
        
      </RadioGroup>
    </FormControl>}
    
    {this.state.openAction == 1 && this.state.receiverType == 1 && <>
    <Typography style={{marginTop:20, marginBottom:5}}>Açılacak Sipariş</Typography>
    <FormControlLabel labelPlacement='start'  control={<SearchBarComponent onValueSelected={(value) => {

        if(value != undefined) {
          this.state.data.orderId = value._id;

          this.state.cityFilter = [value.city];
        } else {
          this.state.data.orderId = undefined;
        }
        console.log(value);
        this.forceUpdate();
    }} search={{orders: 1}}   />} label={this.state.data.orderId?"Sipariş #" + this.state.data.orderId.substring(18).toUpperCase():"Sipariş Seçin"} /></>}


{this.state.filterType == 0 && this.state.receiverType == 1 && <FormControl style={{marginTop:20}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Şehirler</FormLabel>

      <div style={{display:"flex", flexDirection:"row"}}>
    
      <FormControlLabel control={<Checkbox checked={this.state.cityFilter.includes(6)} onChange={(e,checked) => { if(checked) this.state.cityFilter.push(6); else this.state.cityFilter.splice(this.state.cityFilter.indexOf(6), 1); this.forceUpdate() }}  />} label="Ankara" />
      <FormControlLabel control={<Checkbox checked={this.state.cityFilter.includes(34)} onChange={(e,checked) => { if(checked) this.state.cityFilter.push(34); else this.state.cityFilter.splice(this.state.cityFilter.indexOf(34), 1); this.forceUpdate() }}   />} label="İstanbul" />
      <FormControlLabel control={<Checkbox checked={this.state.cityFilter.includes(35)} onChange={(e, checked) => { if(checked) this.state.cityFilter.push(35); else this.state.cityFilter.splice(this.state.cityFilter.indexOf(35), 1); this.forceUpdate() }}   />} label="İzmir" />
      </div>
    </FormControl>}



{/* {this.state.receiverType == 1 && this.state.filterType == 0 && } */}
            </Badge>



            <Badge style={{marginTop:40}}>
                <Typography variant='h6'>Bildirim Ayarları</Typography>


                {this.state.receiverType == 0 && <Typography variant='body1' style={{marginTop:30,marginBottom:5,fontWeight:"600"}}>Türkçe</Typography>}
                <Typography variant='body1' style={{marginTop:10,marginBottom:5}}>Başlık</Typography>
                <TextField value={this.state.title.tr} onChange={(e) => {
                    this.state.title.tr = e.target.value;
                    this.forceUpdate();
                }} size='small' rows={3} multiline onKeyDown={(e) => {
  if(e.keyCode === 13) {
    e.preventDefault();
    
  }
}} />

<Typography variant='body1' style={{marginTop:10,marginBottom:5}}>Metin</Typography>
<TextField value={this.state.body.tr} onChange={(e) => {
                    this.state.body.tr = e.target.value;
                    this.forceUpdate();
                }} size='small' rows={3} multiline onKeyDown={(e) => {
  if(e.keyCode === 13) {
    e.preventDefault();
    
  }
}} />


{this.state.receiverType == 0 && <><Typography variant='body1' style={{marginTop:30,marginBottom:5,fontWeight:"600"}}>İngilizce</Typography>
                <Typography variant='body1' style={{marginTop:10,marginBottom:5}}>Başlık</Typography>
                <TextField value={this.state.title.en} onChange={(e) => {
                    this.state.title.en = e.target.value;
                    this.forceUpdate();
                }} size='small' rows={3} multiline onKeyDown={(e) => {
  if(e.keyCode === 13) {
    e.preventDefault();
    
  }
}} />

<Typography variant='body1' style={{marginTop:10,marginBottom:5}}>Metin</Typography>
<TextField value={this.state.body.en} onChange={(e) => {
                    this.state.body.en = e.target.value;
                    this.forceUpdate();
                }} size='small' rows={3} multiline onKeyDown={(e) => {
  if(e.keyCode === 13) {
    e.preventDefault();
    
  }
}} /></>}

<div style={{marginTop:20, alignSelf:"flex-end"}}>
<Button onClick={() => {
    this.setState({backdropVisible: true});

    var notifications = [{title: this.state.title.tr, body:this.state.body.tr}];
    if(this.state.receiverType == 0 && (this.state.title.en.trim().length+this.state.body.en.trim().length) > 0) notifications.push({title: this.state.title.en, body: this.state.body.en});

    API.fetch("testnotification", {notifications, data: this.state.data}).then((e) => {
        this.setState({backdropVisible: false});
    })
}} variant='outlined' fullWidth={false}>Test Bildirimi Gönder</Button>
</div>
            </Badge>

            {this.state.errorSending != undefined && <div style={{marginTop:10}}>
                <Typography style={{color:"crimson"}}>Bildirimler gönderildiği zaman {this.state.errorSending.length} kişi bu bildirimi alamadı;</Typography>
                <Typography style={{color:"crimson"}}>{this.state.errorSending.join(", ")}</Typography>
            </div>}

            <div style={{alignSelf:"flex-end",display:"flex", justifyContent:"flex-end", marginTop:30}}>
                <div>
                <Button onClick={() => {
                    
                    
                    //if(this.state.filterType == 0) {
                        this.setState({backdropVisible: true});

                        var filters = this.getFilters();
                        API.fetch("notificationgetcount", {receiverType: this.state.receiverType,filters}).then((e) => {
                           
                            this.setState({backdropVisible: false, audienceSize: e, approveSend: true});
                        })
                    //}
                }} fullWidth={false} variant='contained'>Bildirimi Gönder</Button>
                </div>
            </div>
            
            </div>

        <Dialog
        open={this.state.approveSend}
        onClose={() => {
            this.setState({approveSend: false});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Bu bildirimi yollamak istediğinize emin misiniz?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz kitlenin büyüklüyü {this.state.audienceSize[0]}, bunlardan {this.state.audienceSize[1]} kadarı bildirimlerini açmış.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({approveSend: false})}>İptal</Button>
          <Button onClick={() => {
            this.setState({approveSend: false, backdropVisible: true})
            
            API.fetch("sendnotifications", {filters: this.getFilters(),receiverType: this.state.receiverType, data: this.state.data, title: this.state.title, body: this.state.body}).then((e) => {
                this.setState({backdropVisible: false,errorSending: e.errorSending});

                console.log(e)
            })
          }} autoFocus>
            Yolla
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.backdropVisible}
  
>
  <CircularProgress color="inherit" />
</Backdrop>
        </div>
    }


    getFilters() {
        var filters = {...this.state.filters};

    if(this.state.receiverType == 1 && this.state.filters.vehicles) {
        
      filters.vehicles = {$elemMatch: {type:  filters.vehicles}};
      
      if(this.state.filters.vehicles.$in && this.state.filters.vehicles.$in.includes(6)) {
        filters.$or = [{vehicles: filters.vehicles}, {flexible: true}];
        delete filters.vehicles;
      }

      
    }

    if(this.state.receiverType == 1 && this.state.filterType == 0) {
      filters.city = {$in: this.state.cityFilter};
    }
    if(this.state.filterType == 1) {
        filters._id = {$in: this.state.selectedUsers.map((x) => x._id)}
    }
    
    
    return filters;
    }
}




var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}