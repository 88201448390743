import { Badge, Typography,Button, TextField, TextareaAutosize, CircularProgress } from '@mui/material';
import React from 'react';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
export default class DriverAnnouncementsScreen extends React.Component {

    constructor() {
        super();


       
        this.state = {replies: [], data: "", loading: true, announcements: []};
    }

    componentDidMount() {
        API.fetch("getannouncements").then(announcements => {
            this.setState({loading: false, announcements})
        })
    }

    componentWillUnmount() {
       
    }

    
    render() {

        if(this.state.loading) return <div><CircularProgress /></div>
        return <div style={{display:"flex", flexDirection:"column", gap: 20}}>

            <Typography style={{marginBottom: 20}} variant='h5'>Sürücü Duyuruları</Typography>

            {this.state.announcements.map((x, indx) => <div style={{backgroundColor:"white", padding:20, borderRadius:10, display:"flex", justifyContent:"space-between", alignItems:"center"}} key={indx}>

                <div>

                    <Typography variant='h6'>{x.title}</Typography>
                    <Typography>{x.preview}</Typography>
                </div>


                <Link to={'../driverannouncements/' + x._id}><Button><ArrowForwardIcon /></Button></Link>

            </div>)}

            <Link to="../driverannouncements/new"><Button>Yeni</Button></Link>
           
        </div>
    }
}

