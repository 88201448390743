import { Badge, Typography,Button, TextField, TextareaAutosize, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import React from 'react';
import API from '../API';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-classic-plus';

import { convert } from 'html-to-text';


export default class EditAnnouncementScreen extends React.Component {

    constructor(props) {
        super(props);


       
        this.state = {replies: [], data: "", announcement: {
            title: "",
            content: "",
            publish_date: Date.now(),
        }, loading: true};
    }

    componentDidMount() {
        
        const announcementId = this.props.match.params.id;
        if(announcementId == 'new') {
            this.setState({loading: false});
        } else {
           API.fetch("getannouncement", {_id: announcementId}).then(e => {
            if(e.announcement) {
                this.setState({loading: false, announcement: e.announcement});
                console.log(e);
                
            } else {
                this.props.history.goBack();
            }
           })
        }
        
    }

    componentWillUnmount() {
       
    }

    
    render() {

        if(this.state.loading) {


            return <div><CircularProgress /></div>
        }

        return <div style={{display:"flex", flexDirection:"column"}}>

            <Typography style={{marginBottom: 20}} variant='h5'>Duyuru #123123</Typography>


            <TextField onChange={(e) => {this.state.announcement.title = e.target.value; this.forceUpdate()}} label="Başlık" value={this.state.announcement.title} />


            <FormControlLabel  control={<Checkbox onChange={(e) => {this.state.announcement.public = e.currentTarget.checked; this.forceUpdate()}}  />} checked={this.state.announcement.public} label={"Herkese açık mı?"} />


            <FormControlLabel  control={<Checkbox onChange={(e) => {this.state.announcement.signRequired = e.currentTarget.checked; this.forceUpdate()}}  />} checked={this.state.announcement.signRequired} label={"Okudum anladım zorunlu (Girişte göster)"} />
            
            
            
            <div style={{display:"flex", flex:1}}>
            <CKEditor
                    editor={ ClassicEditor }

                    config={{mediaEmbed: {previewsInData: true},simpleUpload: {
                        uploadUrl: API.SERVER_ADDRESS + "uploadannouncementfile",
                        headers: {
                            Authorization: "Bearer " + API.token
                        }
                    }}}
                    
                    data={this.state.announcement.content}
                   
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                       

                        this.editor = editor;
                    } }
                    onChange={ ( event,e) => {
                        this.state.announcement.content = e.data.get();
                        this.forceUpdate();
                    } }
                    
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }

                    
                />

</div>
                <Button onClick={() => {
                    
                    this.setState({loading: true});
                    this.state.announcement.preview = convert(this.state.announcement.content, {wordwrap: 130, selectors: [{selector: 'a',format: "skip"}, {selector: "img", format: "skip"}]}).substring(0,70);
                   

              
                    API.fetch("saveannouncement", {_id: this.props.match.params.id, announcement: this.state.announcement}).then(e => {

                        if(e._id) {

                            return window.location.href = "../driverannouncements/" + e._id;
                        }
                        this.setState({loading: false});
                    })
                }}>Kaydet</Button>
           
        </div>
    }
}

