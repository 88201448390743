import { Badge, Typography,Button, TextField, TextareaAutosize } from '@mui/material';
import React from 'react';
import API from '../API';

export default class RepliesScreen extends React.Component {

    constructor() {
        super();


       
        this.state = {replies: []};
    }

    componentDidMount() {
        API.fetch("getlivesupport").then(e => {
            this.setState({replies: e.replies})
        })
    }

    componentWillUnmount() {
       
    }

    
    render() {
        return <div style={{display:"flex", flexDirection:"column"}}>

            <Typography style={{marginBottom: 20}} variant='h5'>Hızlı Yanıtlar ({this.state.replies.length})</Typography>

         
            {this.state.replies.map((x, i) => <div style={{display:"flex",gap:5, borderTopWidth:1, borderTopStyle:"solid", borderTopColor:"gray",paddingBottom:10, paddingTop:10}} key={i}>


                {!x.edit && <>
                <Typography style={{flex:.3}} variant='h6'>{x.code}</Typography>
                <Typography  style={{flex:.7}} >{x.msg}</Typography>
                <Button onClick={() => {x.edit = true; this.forceUpdate()}}>D</Button>
                </>}

                {x.edit && <>

                <TextField value={x.code} style={{flex:.3}} onChange={(e) => {x.code = e.target.value; this.forceUpdate()}} />
                <TextareaAutosize value={x.msg} style={{flex:.7}} onChange={(e) => {x.msg = e.target.value; this.forceUpdate()}} />
                
                <Button onClick={() => {

                    global.showLoadingPanel();
                    API.fetch("savereply", {reply: x}).then(e => {
                        x._id = e._id;
                        x.edit = false;
                        global.hideLoadingPanel();
                    })

                }}>S</Button>
                </>}
                
                
                
                </div>)}

                <Button onClick={() => {this.state.replies.push({code: "", edit: true, msg: ""}); this.forceUpdate()}} style={{marginTop:10}}>Ekle</Button>
        </div>
    }
}

