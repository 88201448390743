import React from "react";
import Colors from '../Colors';
var times = [14,18,23] //1 less
var DateSelectionButton = (props) => <div onClick={() => props.onPress(props.isSelected)} style={{paddingLeft:5, cursor:"pointer",justifyContent:"center",display:"flex",transition:"all .3s", paddingRight:5, backgroundColor:props.isSelected === true?Colors.Blue:"gray",flex:1,flexDirection:"row", alignItems:"center", borderRadius:15,opacity:props.isSelected == true?1:0.5, userSelect:"none"}}><p style={{fontSize:14,textAlign:"center",userSelect:"none",transition:"all .3s",verticalAlign:"middle",color:props.isSelected==true?"white":"black",flexWrap:"wrap",flexShrink:1}}>{props.date}</p></div>
const months = ["Oca","Şub", "Mar", "Nis", "May","Haz","Tem","Ağu","Eyl","Eki","Kas","Ara"];

export default class JYSKTimetableComponent extends React.Component {


    constructor(props) {
        super(props);

        this.dates = [];

        //if(props.selectedDates == undefined) props.selectedDates = [];
        //this.state = {selectedDates: props.selectedDates || []};
        var nowDate = new Date(this.props.timeStartDate);
       

          for(var i = (nowDate.getHours() > times[2]?1:0); i < (nowDate.getHours()> times[2]?8:7); i++) {
            var v = new Date(this.props.timeStartDate);
            v.setHours(0,0,0,0);

            this.dates.push(new Date(v.getTime()+(86400000*i)));
          }
    }

    render() {
        return <div>
            <div>
                      {this.dates.map((date, i) => <div style={{flexDirection:"row", gap: 10, borderBottomWidth:2,display:"flex",borderLeftWidth:0,borderRightWidth:0, borderTopWidth:0,flex:0, borderStyle:"solid", borderBottomColor:"gray", justifyContent:"center", alignItems:"center",paddingTop:10, paddingBottom:10}} key={i}>

                            <div style={{width:50,justifyContent:"center", alignItems:"center"}}>
                              <p style={{textAlign:"center", fontSize:20, fontWeight:"bold",marginTop:0,marginBottom:0}}>{date.getDate()}</p>
                              <p style={{textAlign:"center", fontSize:18,marginTop:-5,marginBottom:0}}>{months[date.getMonth()]}</p>
                            </div>

                            <div style={{width:2, height:"100%", backgroundColor:"black", opacity:.6,height:43}}></div>


                            <div style={{flexDirection:"row", justifyContent:"space-evenly",gap:10,height:"43px",display:"flex",flex:1}}>
                              
                              <DateSelectionButton isSelected={this.props.selectedDates[(i*3)+0]} onPress={(selected) => {
                                if(new Date().getDate() == date.getDate() && new Date().getHours() >= times[0]) return alert('Seçmiş olduğunuz saat aralığı geçmişte kaldığından dolayı seçilemez');
                                console.log(date.getTime());
                                var a = [...this.props.selectedDates];
                                a[(i*3)+0] = !a[(i*3)+0];
                                this.props.onTimesChanged(a)
                                
                              }} date={"10:00-14:00"} />
                              <DateSelectionButton isSelected={this.props.selectedDates[(i*3)+1]} onPress={(selected) => {
                                if(new Date().getDate() == date.getDate() && new Date().getHours() >= times[1]) return alert('Seçmiş olduğunuz saat aralığı geçmişte kaldığından dolayı seçilemez');
                                
                                var a = [...this.props.selectedDates];
                                a[(i*3)+1] = !a[(i*3)+1];
                                this.props.onTimesChanged(a)
                                
                              }} date={"14:00-18:00"} />
                              <DateSelectionButton isSelected={this.props.selectedDates[(i*3)+2]} onPress={(selected) => {
                                if(new Date().getDate() == date.getDate() && new Date().getHours() >= times[2]) return alert('Seçmiş olduğunuz saat aralığı geçmişte kaldığından dolayı seçilemez');

                                var a = [...this.props.selectedDates];
                                a[(i*3)+2] = !a[(i*3)+2];
                                this.props.onTimesChanged(a)
                               
                                
                              }} date={"18:00-23:00"} />

                              
                            </div>
                      </div>)}

                    </div>

                    {/* <div style={{flexDirection:"row", gap: 30, alignItems:"center", marginTop:20, display:"flex"}}>

                            <div style={{flexDirection:"row", alignItems:"center", gap: 5, display:"flex"}}>
                            <div style={{backgroundColor:Colors.Blue, width:45, height:25, borderRadius:5}}></div>
                            <p style={{fontSize:16, fontWeight:"bold"}}>Müsait</p>
                            </div>


                            <div style={{flexDirection:"row", alignItems:"center", gap: 5, display:"flex"}}>
                            <div style={{backgroundColor:"gray", opacity:.5, width:45, height:25, borderRadius:5}}></div>
                            <p style={{fontSize:16, fontWeight:"bold"}}>Müsait Değil</p>
                            </div>
                      
                    </div> */}
        </div>
    }
}