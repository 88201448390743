import React from "react";
import ItemList from '../items.json';
import Colors from '../Colors';

export default class ItemDescription extends React.Component {

    constructor(props) {
        super(props);

        
    }

    render() {
        // var item = ItemList[this.props.item.index];
  
        return  <div style={{paddingBottom:10, borderBottomWidth:2, borderBottomStyle:"solid", borderBottomColor:"gray"}}><div style={{display:"flex", flexDirection:"row", gap: 10, alignItems:"center"}}>
            <div style={{aspectRatio:"1/1",overflow:"hidden", backgroundColor:this.props.item.barcode != undefined?"#F2F2F2":Colors.LightBlue, width:70, borderRadius:1000, justifyContent:"center", alignItems:"center", display:"flex"}}>

            {this.props.item.barcode != undefined && <img src={"https://cdn1.jysk.com/getimage/wd3.medium/" + this.props.item.barcode} style={{width: 60, height: 60, objectFit:"contain"}} />}
</div>


{this.props.item.barcode != undefined && <div>

    <div>
<p style={{fontWeight:"bold", fontSize:20, flex:1,marginTop:0, marginBottom:0}}>{this.props.item.quantity <= 1?"":(this.props.item.quantity +" adet ")}{this.props.item.name} ({this.props.item.barcode})</p>

    {this.props.item.boxes.map((x, z) => <div style={{borderTopWidth:(z >= 1)?1:0, borderTopStyle:"solid", borderTopColor:"black", marginTop:(z >= 1)?5:0}} key={z}>

        <p>Kutu {z+1}</p>
        <p style={{fontSize:14, flex:1, flexWrap:"wrap", marginTop:0, marginBottom:0}}>Genişlik: {x.width}cm | Derinlik: {x.depth}cm | Yükseklik: {x.height}cm</p>
        <p style={{fontSize:14, flex:1, flexWrap:"wrap", marginTop:0, marginBottom:0}}>Ağırlık: {x.weight}kg</p>
    </div>)}
           
            
            </div>
    
    </div>}
{this.props.item.barcode == undefined && <div>
<p style={{fontWeight:"bold", fontSize:20, flex:1,marginTop:0, marginBottom:0}}>{this.props.item.name.tr}</p>
            <p style={{fontSize:14, flex:1, flexWrap:"wrap", marginTop:0, marginBottom:0}}>Genişlik: {this.props.item.width}cm | Derinlik: {this.props.item.depth}cm | Yükseklik: {this.props.item.height}cm</p>
            <p style={{fontSize:14, flex:1, flexWrap:"wrap", marginTop:0, marginBottom:0}}>Ağırlık: {this.props.item.weight}kg</p>
            
            </div>}

           
            
            </div>
            {this.props.item.images != undefined && <div style={{display:"flex",gap: 10,flexWrap:"wrap",marginTop:10}}>{this.props.item.images.map((x, i) => <img onClick={() => {
                global.openCarousel(this.props.item.images, i);
            }} style={{objectFit:"cover", cursor:"pointer", width:100,borderRadius:15, height:100}} src={x} key={i} />)}</div>}
            
            </div>
    }
}