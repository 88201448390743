import React from 'react';


export default class HomeScreen extends React.Component {

    constructor() {
        super();
    }
    render() {
        return <div>Home Screen


            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
        </div>
    }
}