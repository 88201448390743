import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import LoginScreen from './pages/LoginScreen';
import API from './API';
import { ThemeProvider } from '@mui/system';
import {theme} from './theme/index';
import HomeScreen from './pages/HomeScreen';
import NavBarLayout from './components/NavBarLayout';
import LogisticsScreen from './pages/LogisticsScreen';
import ApplicationsScreen from './pages/ApplicationsScreen';
import ApplicationScreen from './pages/Application';
import DriverScreen from './pages/Driver';
import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DriversScreen from './pages/Drivers';
import LiveSupportScreen from './pages/LiveSupportScreen';
import LiveMap from './pages/LiveMapScreen';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WarningsScreen from './pages/WarningsScreen';
import OrderScreen from './pages/OrderScreen';
import ClientScreen from './pages/Client';
import CloseIcon from '@mui/icons-material/Close';
import FinanceScreen from './pages/FinanceScreen';
import OrderChatWindow from './pages/OrderChatWindow';
import NotificationCenter from './pages/NotificationCenter';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';
import RepliesScreen from './pages/RepliesScreen';
import DriverAnnouncementsScreen from './pages/DriverAnnouncements';
import EditAnnouncementScreen from './pages/EditAnnoucement';
import OrderTranscript from './pages/OrderTranscript';
import CreateOrderScreen from './pages/CreateOrderScreen';
import FlexLogisticsScreen from './pages/FlexLogisticsScreen';
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import SearchBarComponent from './components/SearchBarComponent';

var types = ["../client/","../driver/","../application/","../order/"]
export default class App extends React.Component {

  constructor() {
    super();
    global.setItem = (s,a) => localStorage.setItem(s,a);
    global.openSearch = () => {


      this.setState({open: true, searchValue: "", options: []}); 
      API.fetch("getlastsearch").then((e) => {

        if(this.state.searchValue == "")
          this.setState({options: e.reverse()});
        
      })
    }
    global.openPrompt = (title, body, acceptButtonText, callback) => {
      this.setState({prompt: {title, body,acceptButtonText}});
      this.promptCallback = callback;
    }

    global.showPopup = (title,body) => {
      this.setState({popupOpened: true, popupTitle: title, popupBody: body});
    }

    global.showLoadingPanel = () => this.setState({loadingPanelVisible: true});
    global.hideLoadingPanel = () => this.setState({loadingPanelVisible: false});
    this.state = {open: false, searchValue: "", options: [], prompt: undefined, loadingPanelVisible: false, imageCarousel: undefined,popupOpened: false,popupTitle: "", popupBody: ""}

    global.openCarousel = (images, index) => {
      this.setState({imageCarousel: {images, index}});
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        

      <Router>
  
        <Switch>
          <ProtectedRoute exact path={'/'} component={HomeScreen} />
          <ProtectedRoute exact path={'/logistics'} component={LogisticsScreen} />
          <ProtectedRoute exact path={'/flex'} component={FlexLogisticsScreen} />
          <ProtectedRoute exact path={'/finance'} component={FinanceScreen} />
          <ProtectedRoute exact path={'/applications'} component={ApplicationsScreen} />
          <ProtectedRoute exact path={'/application/:id'} component={ApplicationScreen} />
          <ProtectedRoute exact path={'/driver/:id'} component={DriverScreen} />
          <ProtectedRoute exact path={'/client/:id'} component={ClientScreen} />
          <ProtectedRoute exact path={'/order/:id'} component={OrderScreen} />
          
          <ProtectedRoute exact path={'/drivers'} component={DriversScreen} />
          <ProtectedRoute exact path={'/warnings'} component={WarningsScreen} />
          <ProtectedRoute nopadding exact path={'/livemap'} component={LiveMap} />
          <ProtectedRoute nopadding exact path={'/livesupport/:id?'} component={LiveSupportScreen} />
          <ProtectedRoute exact path={'/replies'} component={RepliesScreen} />
          <ProtectedRoute noLayout nopadding exact path={'/messages/:id/:index'} component={OrderChatWindow} />
          <ProtectedRoute noLayout nopadding exact path={'/transcript/:id'} component={OrderTranscript} />
          <ProtectedRoute exact path={'/notifications'} component={NotificationCenter}/>
          <ProtectedRoute exact path={'/driverannouncements'} component={DriverAnnouncementsScreen}/>
          <ProtectedRoute exact path={'/driverannouncements/:id'} component={EditAnnouncementScreen}/>

          <ProtectedRoute exact path={'/createorder/:id'} component={CreateOrderScreen} />
          <ProtectedRoute exact path={'/createorder'} component={CreateOrderScreen} />
          <Route exact path='/login' component={LoginScreen} />


        </Switch>
        
      </Router>
  
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={() => {this.setState({open: false})}}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <div onClick={() => this.setState({open: false})} style={{position:"absolute", left:0, right:0, top:0, bottom:0, display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div onClick={(event) => event.stopPropagation()} style={{boxShadow: "rgb(100 116 139 / 12%) 0px 10px 15px", padding:20, backgroundColor:"white",width:"90%",maxWidth: 500, borderRadius:5}}>

              <div style={{width:"calc(100% + 20)", borderTopLeftRadius:5, borderTopRightRadius:5, backgroundColor: "#3c9cd7", margin: -20, marginBottom:20,paddingLeft:20, paddingTop:20, paddingBottom:20}}>
                <Typography variant="h6" color={'white'}>Arama</Typography>
              </div>
              {/* <Typography id="transition-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography> */}

              <SearchBarComponent onValueSelected={(v) => {
                
                window.location.href = types[v.type] + v._id;
        }}  />
              {/* <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      filterOptions={(x) => x}
      options={this.state.options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={this.state.searchValue}
      onChange={(event, newValue) => {

        
        window.location.href = types[newValue.type] + newValue._id;
      }}
      onInputChange={(event, newInputValue) => {
        if(this.searchTimeout) clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(() => {
         
          API.fetch("search", {q: newInputValue}).then((e) => {
            this.setState({options: e})
          })
        }, 100);
      }}
      renderInput={(params) => (
        <TextField autoFocus={true} {...params} label="Ara" fullWidth />
      )}
      renderOption={(props, option) => {
        // const matches = option.structured_formatting.main_text_matched_substrings;
        // const parts = parse(
        //   option.structured_formatting.main_text,
        //   matches.map((match) => [match.offset, match.offset + match.length]),
        // );
        var parts = [];

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.label}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    /> */}
              </div>
              </div>
          </Fade>
        </Modal>


        <Dialog
        open={this.state.popupOpened}
        onClose={() => {
            this.setState({popupOpened: false});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {this.state.popupTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            {this.state.popupBody}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={() => {
            this.setState({popupOpened: false});
            
          }} autoFocus>
            Tamam
          </Button>
        </DialogActions>
      </Dialog>

        <Dialog
        open={this.state.prompt != undefined}
        onClose={() => {
            this.setState({prompt: undefined});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {this.state.prompt?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            {this.state.prompt?.body}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({prompt: undefined})}>İptal</Button>
          <Button onClick={() => {
            this.setState({prompt: undefined});
            this.promptCallback();
            
          }} autoFocus>
            {this.state.prompt?.acceptButtonText}
          </Button>
        </DialogActions>
      </Dialog>
      


      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
  open={this.state.loadingPanelVisible}
  
>
  <CircularProgress color="inherit" />
</Backdrop>

{/* <ReactImageCarouselViewer  open={this.state.imageCarousel != undefined} onClose={() => this.setState({imageCarousel: undefined})} images={this.state.imageCarousel?.images || []} startIndex={this.state.imageCarousel?.index} /> */}
<Backdrop  open={this.state.imageCarousel != undefined} onClick={(e) => {if(e.target === e.currentTarget) this.setState({imageCarousel: undefined})}} sx={{backgroundColor: '#000000CC', zIndex:(theme) => theme.zIndex.drawer+1}}>
{this.state.imageCarousel != undefined && <><CloseIcon fontSize='large' onClick={(e) => this.setState({imageCarousel: undefined})} style={{position:"absolute",cursor:"pointer", right:30, top: 30, color:"white"}} />
          
          {(this.state.imageCarousel.index < this.state.imageCarousel.images.length-1) && <ArrowForwardIos fontSize='large' onClick={(e) => {this.state.imageCarousel.index++; this.forceUpdate()}} style={{position:"absolute",cursor:"pointer", right:30, color:"white"}} />}
          {(this.state.imageCarousel.index > 0) && <ArrowBackIos fontSize='large' onClick={(e) => {this.state.imageCarousel.index--; this.forceUpdate()}} style={{position:"absolute",cursor:"pointer", left:30, color:"white"}} />}
          <img style={{maxWidth:"80%",userSelect:"none", maxHeight:"90%", objectFit:"contain"}} src={this.state.imageCarousel.images[this.state.imageCarousel.index]} /></>}
</Backdrop>
      </ThemeProvider>

    );
  }
}



const ProtectedRoute = ({component: Component,noLayout,path, ...rest}) => {

  

  return (<Route exact path={path} {...rest} render={props => {
    var navTitle = "/" + (props.location.pathname.split("/")[1] + "");
    var nav = API.NAVIGATIONS.find((s) => s.href == navTitle);

    

    if(!API.user) return <Redirect to={{pathname: "/login", state:{redirect: props.location.pathname + (props.location.search || "")}}} />
    if((nav && !API.user.permissions.includes(0) && !API.user.permissions.includes(nav.permission))) return <Redirect to={{pathname: "/error", state:{redirect: props.location.pathname}}} />
    
    if(noLayout) return <div style={{display:"flex",flex:1,width:"100vw", height:"100vh",padding: "0px"}}><Component {...props} /></div>
    return <NavBarLayout nopadding={rest.nopadding} {...props} >
      <Component  {...props} />
    </NavBarLayout>
  }} />)
}
