export default function JYSKLogo(props) {
    return <div {...props}><svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    className="logo-outlined"
    version="1.1"
    viewBox="0 0 1998.6 940"
    xmlSpace="preserve"
  >
    <path fill="#143C8A" d="M12.7 12.7H1986V927.4000000000001H12.7z"></path>
    <path
      fill="#FFF"
      d="M1973.3 25.3v889.4H25.3V25.3h1948M1998.6 0H0V940h1998.6V0z"
    ></path>
    <path
      fill="#FFF"
      d="M677.2 256.6v275c0 81.2-57.4 139.6-139.1 139.6-42.2 0-88.7-18.4-121.8-44.4l50.3-126.1c7.6 19.5 16.2 42.8 39.5 42.8 23.8 0 26-27.1 26-44.4V256.6h145.1zM867.9 256.6l45.5 106.6h4.3c4.3-13 8.1-26 13.5-38.4l29.2-68.2h166.7L987.5 488.8v175.9H842.4V488.8L700.1 256.6h167.8zM1140.5 518.6l7.6 6c26.5 20.6 58.5 37.3 92.5 37.3 14.6 0 37.9-5.9 37.9-24.4 0-18.4-19.5-20-33-22.8l-29.7-5.4c-58-10.3-106.6-44.4-106.6-108.2 0-96.9 88.2-151 177-151 46.5 0 89.3 11.4 129.4 35.7l-40.1 104.4c-25.4-17.3-54.1-30.8-86-30.8-13 0-31.9 4.9-31.9 21.6 0 15.2 18.4 17.3 29.8 20l33.6 7.6c62.8 14.1 108.2 47.6 108.2 116.9 0 97.4-88.8 145.6-177 145.6-51.4 0-111-14.1-157-37.3l45.3-115.2zM1473.2 256.6h144.5l-3.3 177h3.3c3.3-16.8 12.4-31.4 20.6-46l71.4-131h161.8l-127.7 198.6L1885 664.7h-172.1l-70.4-123.4c-8.7-15.7-21.6-30.8-24.9-47.6h-3.3l3.3 171h-144.5V256.6zM169.3 171.1c-6 .1-11.2 2.2-14.9 6.1-3.7 3.9-5.6 9.1-5.5 15.1.2 9.2 5.2 19.4 13.4 27.3 14.2 13.7 33 16.4 42.8 6.2 3.7-3.9 5.5-9 5.5-14.7 0-9.1-4.7-19.4-13.4-27.8-8.3-7.8-18.7-12.4-27.9-12.2m27.8 12.3z"
    ></path>
    <path
      fill="#FFF"
      d="M227.6 914.9c6.1-21.8 7.8-44.9 7.8-44.9.7-6.2 1.2-12.6 1.5-19 3.8-77.3-24-173.3-80.9-250.1C98 522.8 61.4 466.3 61.4 419s34.6-70 59.1-75.5 89.1-5.5 112.8-25.5c23.1-19.5 36.4-47.3 33.6-81.9-2.7-34.5-11.2-110.2-128.2-115.5-41.1-1.8-84.3 15-113.3 37.3l.1-61.1C52 83.7 92.3 69.7 143.2 69.7c96.4 0 140.9 61.8 140.9 61.8s57.3 23.6 97.3 54.6c40 30.9 72.7 64.6 75.5 69.1 2.7 4.5 10 21.8-14.6 30-24.5 8.2-73.7 17.3-123.7 17.3 0 0-32.7 58.2-84.6 76.4-51.8 18.2-112 .8-114.6 48.2-1.8 32.7 27.3 71.9 38.2 87.3 10.9 15.5 135.4 139.3 128.2 329.2-1.5 40.8-8 71.3-8 71.3h-50.2m94.2-660.6s28.2 3.7 51.8 4.6c23.7.9 20-10 20-10-10.9-9.1-75.5-45.5-75.5-45.5-9 23.7 3.7 50.9 3.7 50.9z"
    ></path>
  </svg></div>
}