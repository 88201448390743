import { Badge, Typography } from '@mui/material';
import React from 'react';
import API from '../API';

export default class WarningsScreen extends React.Component {

    constructor() {
        super();


        this.updateWarnings();
        this.state = {warnings: []};
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.updateWarnings();
        }, 15000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async updateWarnings() {
        this.setState({warnings: await API.fetch("getwarnings")});

        
    }
    render() {
        return <div style={{display:"flex", flexDirection:"column"}}>Warnings


            {this.state.warnings.map((x, i) => <LogisticAnalytic order={x} first={i == 0} key={i}>
                
                    
                </LogisticAnalytic>)}
        </div>
    }
}

const LogisticAnalytic = (props) => {
    return <div style={{backgroundColor:"white",marginTop:20,display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
     <a href={"../order/" + props.order._id}><Typography>Sipariş #{props.order._id.substring(18).toUpperCase()}</Typography></a>
    
     {props.order.warnings.map((warning, i) => (warning.type != 2?<Typography key={i}>
        {new Date(warning.time).toLocaleString()}'den {(!warning.up)?"Şimdiye":new Date(warning.up).toLocaleString()} {["Sürücüye Ulaşılamıyor", "Sürücü Hareketsiz"][warning.type]} Skor: {warning.score}
     </Typography>:<Typography key={i}>
     {new Date(warning.time).toLocaleString()} {warning.minutesLeft} dakikadan az kaldı ve sürücü yola çıkmadı
     </Typography>))}
       
</div>

}