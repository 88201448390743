import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import API from '../API';
import SearchBarComponent from '../components/SearchBarComponent';
const Towns = require('../istanbultowns.json');
const types = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet", "Siparişler", "Gerçek Zamanlı"];
const VEHICLE_NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet","Esnek"]

const LeafIcon = L.Icon.extend({
  options: {}
});


var STATUS = {};
STATUS[0] = {
    t: "Sürücü Aranıyor",
    color: "rgb(80,72,229)"
}
STATUS[1] = {
    t: "Sürücü Bulundu",
    color: "rgb(80,72,229)"
}
STATUS[3] = {
    t: "Tarih Kesinleştirildi",
    color: "rgb(80,72,229)"
}
STATUS[2] = {
    t: "Aktif Sipariş",
    color: "orange"
}
STATUS[10] = {
    t: "Tamamlanmış",
    color: "green"
}
STATUS[11] = {
    t: "Müşteri İptal",
    color: "red"
}
STATUS[-1] = STATUS[11];
STATUS[12] = {
    t: "Sürücü İptali",
    color:"crimson"
}

const greenIcon = new L.Icon({
  iconUrl:
    "http://maps.google.com/mapfiles/ms/icons/green.png",
  iconAnchor: [16,32],
  iconSize:[32,32],
  popupAnchor: [0,-32]
});

const redIcon = new L.Icon({
  iconUrl:
    "http://maps.google.com/mapfiles/ms/icons/red.png",
  iconAnchor: [16,32],
  iconSize:[32,32],
  popupAnchor: [0,-32]
});
export default class LiveMap extends React.Component {

    constructor() {
        super();

        this.state = {points: [],orders: [], types: [true,true,true,true,true,true,false,false]};

        this.driverMarkers = {};

        this.orderMarkers = {};
        this.reloadMap();
    }

    componentDidMount() {
      this.interval = setInterval(() => {
          this.reloadMap();
      }, 15000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async reloadMap() {
      const v = await API.fetch("getlivemap");
      this.setState({points: v.drivers, orders: v.orders})
    }
    render() {
        return <div style={{display:"flex", flex:1, backgroundColor:"aqua", position:"relative"}}>

            
            {/* <MapContainer center={[41.015137, 28.979530]} zoom={30} scrollWheelZoom={true}>
            <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://api.maptiler.com/maps/bright/{z}/{x}/{y}.png?key=QvhmAooq6IxQP47qd6Ar"
     
  />
               
                </MapContainer> */}

                <div style={{position:"absolute",left:0,right:0,top:0, bottom:0}}>
                
                <MapContainer   center={[41.07011330219962, 29.025937852318005]} style={{width:"100%",height:"100%"}} zoom={10.5} scrollWheelZoom={true}>
                <TileLayer  ref={a => {
                  this.tileLayer = a;
                  
                }}
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
                    {/* {Towns.map((x, i) => <Marker key={i} position={[x.geolocation.lat, x.geolocation.lon]}>
    <Popup>
      {x.name}
    </Popup>
  </Marker>)} */}
  
  {this.state.points.filter((s) => s.vehicles.find((s) => this.state.types[s.type] && s.active != false)).map((x, i) => <Marker ref={(a) => {
    
      this.driverMarkers[x._id] = a;
  }} opacity={this.state.selectedOrder != undefined?.1:(x.online && (!this.state.types[7] || (x.loc && Date.now()-x.loc.time <= 180000)))?1:.4}  key={i} position={[x.loc.coordinates[1], x.loc.coordinates[0]]}>
    <Popup>
      {x.name} • {x.vehicles.filter(s => s.active != false).map((x) => types[x.type]).join(",")} • {(x.credits+x.freeCredits).toFixed(2)}₺
      <br/>
      {new Date(x.loc.time).toLocaleString('tr-TR')}
      {console.log(x)}
    <br></br>
      <a href={'../driver/'+x._id}>{x._id}</a>
    </Popup>
  </Marker>)}

  {this.state.types[6] && this.state.orders.map((x, i) => <Marker opacity={this.state.selectedOrder == undefined || this.state.selectedOrder == x._id?1:.1} eventHandlers={{popupopen: (popup) => {
                 this.state.selectedOrder = x._id;
                 this.forceUpdate();
                }, popupclose: () => {
                  this.state.selectedOrder = undefined;
                  this.forceUpdate();
                }}}  icon={greenIcon} ref={(a) => {
    
    if(!this.orderMarkers[x._id]) this.orderMarkers[x._id] = {};
    this.orderMarkers[x._id].from = a;

    

    
}}   key={i} position={[x.from.coordinates[1], x.from.coordinates[0]]}>
  <Popup  >
  <OrderInnerPopup order={x} />


    <a href={'../order/'+x._id}>{x._id}</a>
  </Popup>
</Marker>)}

{this.state.types[6] && this.state.orders.map((x, i) => <Marker opacity={this.state.selectedOrder == undefined || this.state.selectedOrder == x._id?1:.05} icon={redIcon} eventHandlers={{popupopen: (popup) => {
                 this.state.selectedOrder = x._id;
                 this.forceUpdate();
                }, popupclose: () => {
                  this.state.selectedOrder = undefined;
                  this.forceUpdate();
                }}}  ref={(a) => {
    
    if(!this.orderMarkers[x._id]) this.orderMarkers[x._id] = {};
    this.orderMarkers[x._id].to = a;

    
}}   key={i} position={[x.to.coordinates[1], x.to.coordinates[0]]}>
  <Popup>

    <OrderInnerPopup order={x} />
    {/* {x.name} • {x.vehicles.map((x) => types[x.type]).join(",")} */}
    <a href={'../order/'+x._id}>{x._id}</a>
  </Popup>
</Marker>)}
                </MapContainer>
                
                </div>

                <div  style={{position:"absolute",left:5,bottom:5,zIndex:1000}}>
                <Badge>

                  {types.map((x, i) => <FormControlLabel sx={{padding: "2px"}} control={<Checkbox checked={this.state.types[i]} onChange={(a, val) => {
                    this.state.types[i] = val;
                    this.forceUpdate();

                    
                  }} sx={{padding: "2px"}} key={i} />} label={x}  />)}
                </Badge>
                </div>

                <div  style={{position:"absolute",left:158,bottom:5,zIndex:1000}}>
                <Badge>
                  <div style={{width:150}}>
                  <SearchBarComponent onValueSelected={(val) => {
                    if(this.driverMarkers[val._id]) this.driverMarkers[val._id].openPopup();
                  }}dontFocus search={{drivers: true}} />
                  </div>
                </Badge>
                </div>
                
        </div>
    }
}

const OrderInnerPopup = (props) => {
  return <div>
      
      {VEHICLE_NAMES[props.order.vehicle]} • {props.order.vehicle != 6?new Date(props.order.date).toLocaleString("tr-TR"):(props.order.scheduledStartDate != undefined?(new Date(props.order.scheduledStartDate).toLocaleString("tr-TR") + "-" + zeroPad(new Date(props.order.scheduledEndDate).getHours())+":"+zeroPad(new Date(props.order.scheduledEndDate).getMinutes())):"-")}
      <p style={{marginTop:0,marginBottom:0}}>Durum: {STATUS[props.order.status].t}</p>

      <p style={{marginTop:10,marginBottom:0}}>Nereden: {props.order.from.Label}</p>
      <p style={{marginTop:0,marginBottom:0}}>Nereye: {props.order.to.Label}</p>
  </div>
}

const Badge = (props) => {
  return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:15, color:"rgb(18, 24, 40)",...props.style}}>
      {props.children}
  </div>
}
const zeroPad = (num, places = 2) => String(num).padStart(places, '0')