import React from 'react';
import { Typography, Button, Avatar, Menu,TextField, Select, MenuItem,CircularProgress, Backdrop, Autocomplete, Input, TextareaAutosize } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Document, Page, pdfjs  } from 'react-pdf';
import WhatsappIcon from '../whatsapp.png';
import TownSelectionInput from '../components/TownSelectionInput';


import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const FILES = ["Sürücü Belgesi Ön", "Sürücü Belgesi Arka", "İkametgah Belgesi", "Adli Sicil Kaydı", "Profil Fotoğrafı", "Vergi Levhası"];

const OPTIONAL_FILES = ['K Belgesi','Psikoteknik Belgesi', 'SRC Belgesi'];
const VEHICLE_NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet"];
const Towns = require('../istanbultowns.json');
var IN_EDIT_MODE = false;

var applicationId;

const CITY_OPTIONS = [{label: "İstanbul", id: 34}, {label: "Ankara", id: 6}, {label: "İzmir", id: 35}];


export default class ApplicationScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: true,editing: false, saving: false, approveDriverDialog: false, backdropVisible: false, actionsDropdownOpen: false};

        
        var id = props.match.params.id;
        applicationId = id;
        
        if(!id) props.history.goBack();

        
    }

    handleToggle() {
        this.setState({actionsDropdownOpen: !this.state.actionsDropdownOpen});
    }

    componentDidMount() {
        var id = this.props.match.params.id;
        API.fetch("getapplication", {_id: id}).then((e) => {
            
            if(e.error) return this.props.history.goBack();
            var vehicles = [];
            if(e.vehicles) {
                for(var vehicleId in e.vehicles) {
                    vehicles.push({...e.vehicles[vehicleId], _id: vehicleId});
                }
            }
            e.vehicles = vehicles;
            this.setState({application: e, loading: false});
        })

        this.optionalFilesOpened = {};
        // API.fetch('getapplications').then((e) => {
        //     if(!e.error) this.setState({applications: e});
        // })
    }
    render() {
        IN_EDIT_MODE = this.state.editing;

        var willInform = false;
        if(IN_EDIT_MODE && this.state.application.files) {
            for(var i = 0; i < 5; i++) {
                if(this.state.application.files[i] && this.state.application.files[i].removed) {
                    willInform = true;
                    break;
                }
            }

            if(!willInform) willInform = this.state.application.vehicles.find((s) => (s.ruhsat && s.ruhsat.removed) || (s.side && s.side.removed) || (s.front && s.front.removed)) != undefined;
        }

        
        if(this.state.loading) return <></>

        const possibleOptionalFiles = this.state.application.files?OPTIONAL_FILES.filter((s,i) => this.state.application.files[1000+i] == undefined && this.optionalFilesOpened[i] != true):[];


        return <div style={{display:"flex", justifyContent:"center"}}>

            <div style={{maxWidth:900, width:"100%"}}>
            <div style={{marginTop:40, display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <div style={{display:"flex"}}>
                    <Avatar sx={{width:70, height:70}} src={this.state.application.files && this.state.application.files[4] && this.state.application.urlBase+this.state.application.files[4].path} />
                    <div style={{marginLeft:20}}>
                        <Typography variant='h4'>{this.state.application.firstName + " " + this.state.application.lastName}</Typography>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <Typography variant='body2'>basvuru_id: </Typography>
                            <div style={{marginLeft:10, backgroundColor:"#E5E7EB", borderRadius:50, paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2}}>
                                <Typography style={{fontWeight:"600"}} variant='body2'>{this.state.application._id}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:"flex",flexDirection:"row", alignItems:"center"}}>
                    <a href={"https://wa.me/"+((this.state.application.tel.startsWith("+")?this.state.application.tel.substring(1):(this.state.application.tel.startsWith("0")?"9"+this.state.application.tel:this.state.application.tel.startsWith("5")?"90"+this.state.application.tel:this.state.application.tel)))} target={'_blank'}><img style={{width:25,height:25,marginRight:18}} src={WhatsappIcon} /></a>
                    {!this.state.editing && <Button onClick={() => {

                        this.applicationOld = JSON.parse(JSON.stringify(this.state.application));
                        this.setState({editing: true});
                    }} style={{marginRight:20}} variant='outlined'>Düzenle <EditIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}
                    {this.state.editing && <Button onClick={() => {

                        
                        this.setState({editing: false, application: this.applicationOld});
                    }} style={{marginRight:20}} color='error' variant='outlined' >İptal <CloseIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}
                    {/* {!this.state.editing && <Button onClick={() => this.setState({approveDriverDialog: true})} variant='contained'>Başvuruyu Onayla</Button>} */}
                    <ButtonGroup variant="contained" ref={a => this.anchorRef = a} aria-label="split button">
        
        <Button
          size="small"
          aria-controls={this.state.actionsDropdownOpen ? 'split-button-menu' : undefined}
          aria-expanded={this.state.actionsDropdownOpen ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => this.handleToggle()}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={this.state.actionsDropdownOpen}
        
        anchorEl={this.anchorRef}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(e) => this.handleClose(e)}>
                <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem onClick={() => {
                        // this.setState({backdropVisible: true});

                        // API.fetch("cancelorder", {_id: this.state.order._id}).then((e) => {
                        //     console.log(e);
                        //     if(!e.error) {
                        //         this.state.order.status = -1;
                        //         delete this.state.order.driver;
                        //     }

                        //     this.setState({backdropVisible: false});

                            
                        // })

                        this.setState({approveDriverDialog: true})
                    }}>Başvuruyu Onayla</MenuItem>


                    <MenuItem onClick={() => {


                        global.openPrompt("Başvuru silme?", "Başvuruyu silmek istediğinize emin misiniz?", "Onayla", () => {
                            global.showLoadingPanel();

                            API.fetch("refuseapplication", {_id: this.state.application._id}).then((e) => {
                            
                                location.reload();
    
                                
                            })
                        })
                        

                        
                    }}>Başvuruyu Sil</MenuItem>

                    
                  
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
                </div>
            </div>
<Badge style={{marginTop:20,padding:0}}>
                <div style={{padding:0}}>
                <Typography style={{marginBottom:20, marginLeft:24, marginTop:24}} variant='h6'>Detaylar</Typography>
                <DetailText first label={"Ad"} onChange={(e) => {this.state.application.firstName = e; this.forceUpdate()}} value={this.state.application.firstName} />
                <DetailText label={"Soyad"} onChange={(e) => {this.state.application.lastName = e; this.forceUpdate()}} value={this.state.application.lastName} />
                <DetailText label={"Email"} onChange={(e) => {this.state.application.email = e; this.forceUpdate()}} value={this.state.application.email} />
                <DetailText label={"T.C Kimlik"} onChange={(e) => {this.state.application.tc = e; this.forceUpdate()}} value={this.state.application.tc} />
                <DetailText label={"VKN"} onChange={(e) => {this.state.application.vkn = e; this.forceUpdate()}} value={this.state.application.vkn} />
                <DetailText label={"IBAN"} onChange={(e) => {this.state.application.iban = e; this.forceUpdate()}} value={this.state.application.iban} />
                <DetailText label={"Telefon"} onChange={(e) => {this.state.application.tel = e; this.forceUpdate()}} value={this.state.application.tel} />
                <DetailText label={"Araç Tipi"} onChange={(e) => {this.state.application.type = e; this.forceUpdate()}} value={VEHICLE_NAMES[this.state.application.type]} />
                <DetailText customEdit={() => <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={CITY_OPTIONS}
        sx={{ width: 300, position:"absolute", left:"20%", padding:0 }}
        onChange={(e,val) => {
            this.state.application.city = val.id;
            this.forceUpdate();
        
        }}
        defaultValue={CITY_OPTIONS.find(s => s.id == this.state.application.city)}
        renderInput={(params) => <TextField autoComplete='off' {...params} label="İl"  />}/>} label={"İl"} value={API.getCityName(this.state.application.city)} />
                <DetailText editable={false} label={"Başvuru Tarihi"} value={dateFromObjectId(this.state.application._id).toLocaleString("tr-TR")} />
                {this.state.application.status == 1 && this.state.application.submitDate != undefined && <DetailText editable={false} label={"Tamamlama Tarihi"} value={new Date(this.state.application.submitDate).toLocaleString("tr-TR")} />}
                <DetailText customEdit={() => <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={Towns.map((x, i) => ({label: x.name, id: i}))}
        sx={{ width: 300, position:"absolute", left:"20%", padding:0 }}
        onChange={(e,val) => {
            this.state.application.prefferedTown = val.id;
            this.forceUpdate();
        
        }}
        renderInput={(params) => <TextField autoComplete='off' {...params} label="İlçe"  />}/>} label={"Çalışma Bölgesi"}  value={(this.state.application.prefferedTown?Towns[this.state.application.prefferedTown].name:"")} />
                
                <DetailText label={"Not (Gizli)"} customDisplay={() => <TextareaAutosize value={this.state.application.note} minRows={3} style={{marginLeft: 80 ,display:"flex", flex:1}} disabled  />} value={this.state.application.note || ""} customEdit={() => <TextareaAutosize value={this.state.application.note} minRows={3} style={{marginLeft: 80 ,display:"flex", flex:1}} onChange={(e) => {this.state.application.note = e.currentTarget.value; this.forceUpdate()}}  />} />

                </div>
            
            </Badge>


            {FILES.map((x, i) => <Badge style={{marginTop:30}} key={i}>

                {/* <Typography variant='h6'>{x}</Typography>
                <div style={{display:"flex", justifyContent:"center", marginTop:24}}>
                
                {this.state.application.files && this.state.application.files[i] && <FilePreview style={{width:"50%", borderRadius:(i == 4)?10000:0}} file={this.state.application.files[i]} urlBase={this.state.application.urlBase} />}
                </div> */}

                
                
                    <FilePreview path={i} onDelete={() => {
                        if(!this.state.editing) return;

                        this.state.application.files[i] = {removed: true}; this.forceUpdate()
                        
                    }} title={x} style={{width:"50%", borderRadius:(i == 4)?10000:0}} file={this.state.application.files?this.state.application.files[i]:undefined} urlBase={this.state.application.urlBase} />
                
            </Badge>)}

            {this.state.application.files != undefined && OPTIONAL_FILES.filter((s,i) => this.state.application.files[1000+i] != undefined || this.optionalFilesOpened[i] == true).map((x, i) => <Badge style={{marginTop:30}} key={i}>

                {/* <Typography variant='h6'>{x}</Typography>
                <div style={{display:"flex", justifyContent:"center", marginTop:24}}>
                
                {this.state.application.files && this.state.application.files[i] && <FilePreview style={{width:"50%", borderRadius:(i == 4)?10000:0}} file={this.state.application.files[i]} urlBase={this.state.application.urlBase} />}
                </div> */}

                
                
                    <FilePreview path={1000+OPTIONAL_FILES.indexOf(x)} onDelete={() => {
                        if(!this.state.editing) return;

                        this.state.application.files[1000+i] = {removed: true}; this.forceUpdate()
                        
                    }} title={x} style={{width:"50%", borderRadius:(i == 4)?10000:0}} file={this.state.application.files?this.state.application.files[1000+OPTIONAL_FILES.indexOf(x)]:undefined} urlBase={this.state.application.urlBase} />
                
            </Badge>)}

            {possibleOptionalFiles.length > 0 && <div style={{marginTop: 40, gap: 10, display:"flex"}}>
            {possibleOptionalFiles.map((x, i) => <Button variant='contained' onClick={() => {
                if(!this.state.application.files) this.state.application.files = [];
                this.optionalFilesOpened[OPTIONAL_FILES.indexOf(x)] = true;
                this.forceUpdate();
            }} key={i}>{x} Ekle</Button>)}

            </div>}

            {this.state.application.vehicles && this.state.application.vehicles.map((vehicle, i) => <Badge style={{marginTop:50, padding:0, paddingBottom:24}} key={i}>
                
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", paddingLeft: 24, paddingRight:24}}>
                <Typography style={{marginBottom:24, marginTop:24}} variant='h6'>ARAÇ #{i}</Typography>
                {IN_EDIT_MODE && vehicle.deleted != true && <div style={{marginLeft:10}}>
                    <Button onClick={() => {
                       vehicle.deleted = true;
                       this.forceUpdate();
                    }} variant='outlined' color='error'>Sil</Button>
                </div>}

                {IN_EDIT_MODE && vehicle.deleted == true && <div style={{marginLeft:10}}>
                    <Typography color='crimson'>Başvuru kaydedildiğinde araç silinecektir</Typography>
                </div>}
                </div>

                <DetailText onChange={(e) => {vehicle.license = e; this.forceUpdate()}} first label={"Plaka"} value={vehicle.license} />
                <DetailText customEdit={() => <Select onChange={(e) => {vehicle.type = e.target.value; this.forceUpdate()}} value={vehicle.type} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}} style={{position:"absolute", left:"20%"}}>
                    <MenuItem value={0}>Motorsiklet</MenuItem>
                    <MenuItem value={2}>Doblo</MenuItem>
                    <MenuItem value={3}>Minivan</MenuItem>
                    <MenuItem value={4}>Panelvan</MenuItem>
                    <MenuItem value={5}>Kamyonet</MenuItem>
                    
                </Select>} label={"Araç Tipi"} value={VEHICLE_NAMES[vehicle.type]} />

                <DetailText onChange={(e) => {vehicle.innerWidth = e; this.forceUpdate()}}  label={"Genişlik (cm)"} value={vehicle.innerWidth} />
                <DetailText onChange={(e) => {vehicle.innerHeight = e; this.forceUpdate()}}  label={"Yükseklik (cm)"} value={vehicle.innerHeight} />
                <DetailText onChange={(e) => {vehicle.innerDepth = e; this.forceUpdate()}}  label={"Uzunluk (cm)"} value={vehicle.innerDepth} />
                
                {/* <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>Ruhsat</Typography>
                {vehicle.ruhsat && <FilePreview style={{width:"50%"}} urlBase={this.state.application.urlBase} file={vehicle.ruhsat} />}

                <div style={{display:"flex", marginTop:24}}>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                <Typography style={{textAlign:"center", marginBottom:10}} variant='h6'>Araç Ön</Typography>
                {vehicle.front && <FilePreview style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.front} />}
                    </div>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                <Typography style={{textAlign:"center", marginBottom:10}} variant='h6'>Araç Yan</Typography>
                {vehicle.side && <FilePreview style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.side} />} */}


                <FilePreview path={applicationId+"/vehicle_"+vehicle._id+"_ruhsat"} onDelete={() => {
                    
                    if(IN_EDIT_MODE) vehicle.ruhsat = {removed: true}; this.forceUpdate()
                    
                }} title={'Ruhsat'} style={{width:"50%"}} urlBase={this.state.application.urlBase} file={vehicle.ruhsat} />

                <div style={{display:"flex", marginTop:24}}>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                
                        <FilePreview path={applicationId+"/vehicle_" + vehicle._id +"_front"} onDelete={() => {
                    
                    if(IN_EDIT_MODE) vehicle.front = {removed: true}; this.forceUpdate()
                    
                }}   title={'Araç Ön'} style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.front} />
                    </div>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                
                        <FilePreview path={applicationId+"/vehicle_" + vehicle._id +"_side"} onDelete={() => {
                    
                    if(IN_EDIT_MODE) vehicle.side = {removed: true}; this.forceUpdate()
                    
                }}   title={'Araç Yan'} style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.side} />
                    </div>
                </div>
            </Badge>)}

            {IN_EDIT_MODE && <div style={{display:"flex", justifyContent:"flex-end", marginTop:24, alignItems:"center"}}>
                {willInform && <Typography fontSize={13} style={{marginRight:5}} color={'InfoText'}>Gerekli belgeleri tekrar yüklenmesi için sürücüye bir e-posta yollanıcak</Typography>}
                {!this.state.saving && <Button onClick={() => {
                    this.setState({saving: true});

                    if(willInform) this.state.application.status = 0;
                    API.fetch("saveapplication", this.state.application).then((e) => {
                        if(this.state.application?.vehicles) {
                            this.state.application.vehicles = this.state.application.vehicles.filter(s => s.deleted != true);
                        }
                        this.setState({saving: false, editing: false});
                        
                       

                    })
                }} variant='contained' color='success' size='medium'>Kaydet</Button>}
                {this.state.saving && <CircularProgress />}
            </div>}
        </div>

        <Dialog
        open={this.state.approveDriverDialog}
        onClose={() => {
            this.setState({approveDriverDialog: false});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sürücü profilini oluşturmakta emin misiniz?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Başvuruda eksik ve ya hatalı bir giriş olmadığından emin olduktan sonra sürücü profilini oluşturun
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({approveDriverDialog: false})}>İptal</Button>
          <Button onClick={() => {
            this.setState({approveDriverDialog: false, backdropVisible: true})
            
            API.fetch("approveapplication", {_id: this.state.application._id}).then((e) => {
                if(e._id) {
                    window.location.replace("../driver/" + e._id);
                } else {
                    this.setState({backdropVisible: false});
                }
            })
          }} autoFocus>
            Oluştur
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.backdropVisible}
  
>
  <CircularProgress color="inherit" />
</Backdrop>
        </div>
    }
}

const FilePreview = ({file, urlBase,title,path, ...rest}) => {

    return <div>

        

        {!IN_EDIT_MODE && <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>

            
        {/* <Button onClick={() => {
            rest.onDelete();
        }} variant='outlined' color='error'>Sil</Button> */}
        <Typography style={{marginBottom:24, marginRight:24, marginTop:24}} variant='h6'>{title}</Typography>

        <Button
  variant="contained"
  component="label"
>
  Dosya Yükle
  <input
    type="file"
    hidden
    onChange={async e => {
        global.showLoadingPanel();
        const ff = e.target.files[0]

        
        API.fetch('uploadfile', {file: ff.name,id: applicationId, path}).then(async e => {
            
            if(!e.url) return;

            let formData = new FormData()
            formData.append('file', ff);
            
            
            const response = await fetch(e.url, {method: "PUT",body:ff,headers: {
                    "Content-Type":"multipart/form-data"
            }});

            

            
           
            //console.log(response);
           location.reload();
            //global.hideLoadingPanel();
        })
        
    }}
  />
</Button>


        </div>}


        {IN_EDIT_MODE && <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>

       
            {/* <Button onClick={() => {
                rest.onDelete();
            }} variant='outlined' color='error'>Sil</Button> */}
        <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>{title}</Typography>

        {file && !file.removed && <div style={{marginLeft:10}}>
            <Button onClick={() => {
                rest.onDelete();
            }} variant='outlined' color='error'>Sil</Button>
        </div>}

        
        </div>}
        <div className='PdfDiv' style={{display:"flex", justifyContent:"center"}}>
        
        {file && !file.removed && (file.path.endsWith(".png") || file.path.endsWith(".jpeg") || file.path.endsWith(".jpg")) && <img {...rest}  src={urlBase+file.path} />}
        {file && !file.removed && (file.path.endsWith(".pdf")) && <Document  onDocumentLoad={(e) => console.log(e)} onLoadError={(error) => console.log("Inside Error", error)} file={{url: urlBase+file.path}}>
        


        <Page width={window.innerWidth*.5} pageNumber={1} />
      </Document>}
        {file && file.removed && <Typography variant='h6' color='crimson'>KALDIRILDI</Typography>}
        {!file && <Typography>Dosya Yüklenmemiş</Typography>}
        </div> 
    </div>
}

const DetailText = (props) => {
    return <div style={{position:"relative",display:"flex", paddingTop:10, paddingBottom:10,alignItems:"center", borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
        <Typography style={{fontWeight:"600", marginLeft:24}}>{props.label}</Typography>


        {(!IN_EDIT_MODE || props.editable == false) && props.customDisplay == undefined && <Typography style={{position:"absolute", left:"20%"}}>{props.value}</Typography>}

        {(!IN_EDIT_MODE || props.editable == false) && props.customDisplay != undefined && props.customDisplay()}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()}
    </div>
}

var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}