import React from 'react';
import API from '../API';
import {CircularProgress, Backdrop,Typography, Avatar,TextField,Button, Link, Tooltip } from '@mui/material'
import {TextareaAutosize} from '@mui/base';

import { Link as BrowserLink, withRouter } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { animateScroll } from "react-scroll";
import {io} from 'socket.io-client';
import './LiveSupportScreen.css';
import { SipProvider } from '@evercall/react-sip';
import SearchBarComponent from '../components/SearchBarComponent';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LockIcon from '@mui/icons-material/Lock';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FileUploader } from "react-drag-drop-files";
import AttachFileIcon from '@mui/icons-material/AttachFile';
class LiveSupportScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {opened: true,loading: true,sideBarOpened: true, draggingFile: false};
    }

    componentDidMount() {

        this.altMessageOnLoad = window.location.href.split("?alt=")[1];
        if(this.altMessageOnLoad) {
            this.altMessageOnLoad = JSON.parse(decodeURIComponent(this.altMessageOnLoad));
        }
        
        global.clickMenuButton = () => {
            if(window.innerWidth > 700) return true;


            if(!this.state.sideBarOpened) {
                this.setState({sideBarOpened: true});
                return false;
            }

            return true;
        }
        this.socket = io(API.SOCKET_ADDRESS, {auth: {type: 2, token: API.token}});
        
        this.socket.on('disconnect', (e) => {
            this.setState({opened: false});
        })
        
        this.socket.on("connect", () => {
            
           
            API.fetch("getlivesupport").then((data) => {
                this.setState({support: data.support, replies: data.replies, loading: false});

                if(this.props.match.params.id) {
                    this.openConversation(this.props.match.params.id);
                    
                }
            })
        })
        

        // API.fetch("getlivesupport").then((data) => {
        //     this.setState({support: data.support, replies: data.replies, loading: false});

        //     if(this.props.match.params.id) {
        //         this.openConversation(this.props.match.params.id);
                
        //     }
        // })

        this.socket.on("updateconversation", (_id, newSet) => {
            var support = this.state.support.find((s) => s._id == _id);

            if(support) {
                Object.keys(newSet).forEach(key => support[key] = newSet[key]);

                if(this.state.openedChat && _id == this.state.openedChat._id) {
                    Object.keys(newSet).forEach(key => this.state.openedChat[key] = newSet[key]);
                }


                this.forceUpdate();
            }
        })

        this.socket.on("typing", (userId, userName, conversationId,end) => {

            if(userId == API.user.id) return;

            var conversationFound = this.state.support.find(s => s._id == conversationId);

            
            if(conversationFound != null) {
                if(!conversationFound.events) conversationFound.events = [];

                var indx = conversationFound.events.findIndex(s => s.userId == userId);

                if(indx == -1 && !end) {
                    indx = conversationFound.events.length;
                    conversationFound.events.push({});
                }

                if(!end)
                conversationFound.events[indx] = {userId, event: userName + " yazıyor...", timeout: Date.now()+5000};
                else if(indx != -1) {
                    conversationFound.events.splice(indx, 1);
                }
                this.forceUpdate();
            }
           
        })

        this.socket.on("error", (e) => console.error(e));
        
        this.socket.on("endchat", (data) => {
            var indx = this.state.support.findIndex((s) => s._id == data._id);
            if(indx != -1) this.state.support.splice(indx,1);

            if(this.state.openedChat && this.state.openedChat._id == data._id)
                this.state.openedChat = null;

            this.forceUpdate();
        })

        this.socket.on("seen", (_id, indexes) => {
            var support = this.state.support.find((s) => s._id == _id);

            if(support) {
                
                

                if(this.state.openedChat && _id == this.state.openedChat._id) {
                    
                    indexes.forEach(s => {
                        this.state.openedChat.liveSupport.messages[s].seen = true;
                    })

                    this.forceUpdate();
                    
                }
            } 
        })

        this.socket.on("chatdelete", (_id, indx) => {
            var support = this.state.support.find((s) => s._id == _id);

            if(support) {
            if(this.state.openedChat && _id == this.state.openedChat._id) {
                    
               
                    this.state.openedChat.liveSupport.messages[indx].deleted = true;
                
                this.forceUpdate();
                
            }
            }
        })
        this.socket.on("chat", (data) => {

            if(data.sender == API.user.id || (data.message != undefined && data.message.reply != undefined && data.message.reply._id != -2)) return;

            var support = this.state.support.find((s) => s._id == data._id);

        
            if(support) {
                support.lastMessage = data.message;
                

                if(this.state.openedChat && data._id == this.state.openedChat._id) {
                    
                    this.state.openedChat.liveSupport.messages.push(data.message);
                    setTimeout(() => {
                        this.setState(animateScroll.scrollToBottom({
                            containerId: "messagesContainer",
                            duration: 0
                        }));
                    }, 100);
                }
            } else {
                
                API.fetch("getchat", {id: data._id}).then((e) => {

                    

                    e.lastMessage = e.liveSupport.messages[e.liveSupport.messages.length-1];
                    this.state.support.push(e);
                    //this.state.support.push({lastMessage:data.message, _id: data._id, name: e.name});
                    this.state.support.sort((a,b) => {
                        //b.liveSupport.messages[b.liveSupport.messages.length-1].d-a.liveSupport.messages[a.liveSupport.messages.length-1].d
                        if(!a.lastMessage) return 1;
                        if(!b.lastMessage) return -1;
        
                        return b.lastMessage.d-a.lastMessage.d;
                    });
                    this.forceUpdate();
                })
                return;
            }

            this.state.support.sort((a,b) => {
                //b.liveSupport.messages[b.liveSupport.messages.length-1].d-a.liveSupport.messages[a.liveSupport.messages.length-1].d
                if(!a.lastMessage) return 1;
                if(!b.lastMessage) return -1;

                return b.lastMessage.d-a.lastMessage.d;
            });
            this.forceUpdate();
        })
        
        
        
        
    }

    componentWillUnmount() {
        this.socket.disconnect();
        this.socket.removeAllListeners();

        global.clickMenuButton = undefined;
    }

    setTyping(_id, end) {

        API.fetch("settyping", {_id, end});
        //this.socket.emit("broadcast", "typing", API.user.id, API.user.name, this.state.openedChat._id, true);
    }

    openConversation(_id) {
        
    
        this.setState({conversationLoading: true,sideBarOpened: false});
    
        API.fetch('getchat', {id: _id}).then((chat) => {
            var supportObject = this.state.support.find((s) => s._id == chat._id);

            
            if(!supportObject) {
                supportObject = {_id: chat._id, p: chat.p, assignedWorkerId: chat.assignedWorkerId, assignedWorkerName: chat.assignedWorkerName};
                this.state.support.unshift(supportObject);
            }
            supportObject.name = chat.name;
            supportObject.driverId = chat.driverId;
            supportObject.clientId = chat.clientId;
            supportObject.platform = chat.platform;
            if(!supportObject.value) supportObject.value = "";

            
            chat.supportObject = supportObject;
            if(!chat.liveSupport) chat.liveSupport = {messages: []}

            this.setState({openedChat: chat, conversationId: chat._id});

            setTimeout(() => {
                this.setState(animateScroll.scrollToBottom({
                    containerId: "messagesContainer",
                    duration: 0
                  }));
            }, 100);
        })
    }
    render() {
        
        if(this.state.loading)
            return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            
          >
            <CircularProgress color="inherit" />
          </Backdrop>


        if(!this.state.opened)
            return <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <Typography>Aynı anda sadece bir tane canlı destek sayfası açabilirsiniz</Typography>
                <Typography>Lütfen açtığınız pencereden veya sayfayı yenileyerek devam edin</Typography>
            </div>


        const supportRegex = this.state.openedChat?.supportObject?.value != undefined && this.state.openedChat?.supportObject?.value.startsWith("/") && this.state.openedChat.supportObject.value.length > 1?new RegExp(diacriticSensitiveRegex(this.state.openedChat?.supportObject?.value.substring(1)),"i"):new RegExp("");
        
        
        return <div style={{display:"flex",flex:1}}>
            <div className={'sideBarSupportPanel '+(this.state.sideBarOpened?"enabled":"")} style={{backgroundColor:"white", borderLeft:"1px solid #E6E8F0",display:"flex",flexDirection:"column",overflowX:"hidden",height:"calc(100vh - 65px)",overflowY:"scroll",width:"350px"}}>
                
                <div style={{padding:15}}>
                <SearchBarComponent dontFocus onValueSelected={(value) => {
                    // console.log(value);
                    
                    // var s = this.state.support.find((s) => s._id == value._id);
                    
                    // if(s) {
                    //     if(!s.value) s.value = "";
                    //     this.setState({openedChat: s})
                    // } else {
                    //     var v = {liveSupport: {messages: []},...s, value: ""};
                    //     this.state.support.push(v);
                    //     this.setState({openedChat: v});
                    // }
                    this.openConversation(value._id);
                }} search={{drivers: true, clients:true}}   />
                </div>
                
                {this.state.support.filter(s => s.lastMessage == undefined || s.lastMessage.aiID == undefined || s.lastMessage.aiID == -2).map((x, i) => <div onClick={() => {
                    // if(!x.value) x.value = "";
                    // this.setState({openedChat: x});

                    // setTimeout(() => {
                    //     this.setState(animateScroll.scrollToBottom({
                    //         containerId: "messagesContainer",
                    //         duration: 0
                    //       }));
                    // }, 100);
                    this.setState({conversationId: x._id, conversationLoading: true});
                    this.openConversation(x._id);
                }} style={{display:"flex",borderBottom:"1px solid #EFF0F1", backgroundColor:(x._id == this.state.conversationId?"#EFF0F1":"white"), transition:"all 250ms",padding:"24px", pointer:"cursor"}} key={i}>
                        
                        <Avatar slotProps={{img:{loading:"lazy"}}} src={(x.driverId)?"https://ik.imagekit.io/tasimacim/driver/tr:w-80/"+x.driverId+".jpeg":""} /> {/**https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/ */}
                        <div style={{marginLeft:10, position:"relative"}}>
                        <Typography style={{margin:0,fontWeight:"800",opacity:.4,fontSize:10,marginTop:-13, fontStyle:"italic",position:"absolute"}} variant='caption'>{x.driverId != undefined?"SÜRÜCÜ":"MÜŞTERİ"}</Typography>
                            <Typography variant='subtitle2'>{x.name}</Typography>
                            <Typography style={{textOverflow:"ellipsis",overflowY:"hidden"}} color="#738392" fontSize={13} variant='subtitle2'>{x.lastMessage?((x.lastMessage.p?"Sen: ":"")+x.lastMessage.m):""}</Typography>

                        </div>
                    </div>)}
                
                
            </div>
            {this.state.openedChat != undefined && <div style={{display:"flex", flex:1, flexDirection:"column"}}>
                <div style={{display:"flex", padding:"10px", backgroundColor:"white", justifyContent:"space-between",borderBottom:"1px solid #E6E8F0"}}>
                    <div style={{display:"flex", alignItems:"center",gap:10}}>
                    <Avatar  src={(this.state.openedChat.supportObject.driverId)?"https://ik.imagekit.io/tasimacim/driver/tr:w-auto/"+this.state.openedChat.supportObject.driverId+".jpeg":""} /> {/**https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/ */}
                    <div style={{display:"flex",flexDirection:"column"}}>
                    <Typography style={{margin:0,fontWeight:"800",opacity:.4,fontSize:12,marginBottom:-5, fontStyle:"italic"}} variant='caption'>{this.state.openedChat.supportObject.driverId != undefined?"SÜRÜCÜ":"MÜŞTERİ"}</Typography>
                    {(this.state.openedChat.supportObject.clientId != undefined  || this.state.openedChat.supportObject.driverId != undefined) && <BrowserLink target={"_blank"}  to={this.state.openedChat.supportObject.clientId?("/client/"+this.state.openedChat.supportObject.clientId):(this.state.openedChat.driverId?"/driver/" + this.state.openedChat.driverId:undefined)}><Typography  variant='subtitle2'>{this.state.openedChat.name + " " + API.getPlatformEmoji(this.state.openedChat.platform)}</Typography></BrowserLink>}
                    
                    {this.state.openedChat.supportObject.clientId == undefined && this.state.openedChat.supportObject.driverId == undefined && <Typography  variant='subtitle2'>{this.state.openedChat.supportObject.name}</Typography>}
                    </div>
                    </div>

                    <div>
                        <Button onClick={() => {
                            API.fetch("endlivechat", {_id: this.state.openedChat._id});
                            var indx = this.state.support.findIndex((s) => s._id == this.state.openedChat._id);
                            if(indx != -1) this.state.support.splice(indx,1);
                            this.setState({openedChat: undefined, conversationId: undefined});
                        }}>Sohbeti Bitir</Button>
                    </div>
                </div>
                <div style={{display:"flex", flex:1,position:"relative"}}>
                <div id="messagesContainer" style={{left:0,right:0,top:0,bottom:0, position:"absolute", overflowY:"auto",padding:"24px",display:"flex",flexDirection:"column"}}>
                    {this.state.openedChat.hasOld == true && !this.state.openedChat.oldLoading && <Button onClick={() => {
                        this.state.openedChat.oldLoading = true;
                        this.forceUpdate();

                        var filter = {status: 10};
                        if(this.state.openedChat.clientId) filter.clientId = this.state.openedChat.clientId;
                        else filter.driverId = this.state.openedChat.driverId;
                        API.fetch("loadallchat", filter).then(e => {
                            
                            this.state.openedChat.liveSupport.messages.unshift(...e);
                            this.state.openedChat.oldLoading = false;
                            this.state.openedChat.hasOld = false;
                            this.forceUpdate();
                        });
                    }}>Eski Sohbetleri Yükle</Button>}

                    {this.state.openedChat.oldLoading == true && <CircularProgress />}
                    
                    {this.state.openedChat.liveSupport.messages.map((msg, i) => <div key={i} style={{width:window.innerWidth<700?"80%":"40%", marginTop:(i==0)?0:24, alignSelf:(msg.p)?"flex-end":"flex-start"}}>
                        {msg.from != undefined && <div style={{backgroundColor:(msg.p?"#5048E5":"white"),marginBottom:5,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:12}} >
                        <Typography color={msg.p?"white":"black"} variant='subtitle2'>Sipariş: <BrowserLink target={'_blank'} to={'/order/'+msg.from}><Link>{msg.from}</Link></BrowserLink></Typography>
                            
                            </div>}

                            {msg.altMessage != undefined && <div style={{backgroundColor:(msg.p?"#5048E5":"white"),marginBottom:5,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:12}} >
                        
                        
                        <BrowserLink target={'_blank'} to={msg.altMessage.data && (msg.altMessage.data.orderId || msg.altMessage.data._id)?'/order/'+(msg.altMessage.data.orderId || msg.altMessage.data._id):undefined}><Typography color={msg.p?"white":"black"} variant='subtitle2'>{msg.altMessage.text}</Typography></BrowserLink>
                            </div>}

                            
                        <div style={{backgroundColor:(msg.note?"#FFF2D5":(msg.p?"#5048E5":"white")),boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8, position:"relative",padding:12}} >
                            <Typography color={msg.note?"#1F2D3D":(msg.p?"white":"black")} variant='subtitle2'>{(msg.p)?"Sen":this.state.openedChat.name}</Typography>

                            {msg.m != undefined && <Typography color={msg.note?"#1F2D3D":(msg.p?"white":"black")} variant='body1' style={{marginTop:12}}>{msg.m}</Typography>}
                            {msg.img != undefined && <a href={(msg.img.includes("http"))?msg.img:"https://tasimacim-chat-attachments.s3.eu-central-1.amazonaws.com/"+msg.img+".jpeg"} target={'_blank'}><img style={{width:"100%",borderRadius:8, marginTop:8}} src={(msg.img.includes("http"))?msg.img:"https://tasimacim-chat-attachments.s3.eu-central-1.amazonaws.com/"+msg.img+".jpeg"} /></a>}
                            
                            {msg.deleted != undefined && <Typography>Bu mesaj silinmiştir ve kullanıcıda gözükmez</Typography>}
                            
                                
                                
                                <Typography style={{textAlign:"right",marginTop:2,color:msg.p?"lightgray":"lightgray"}}>{msg.seen != undefined && 
                                    <Tooltip title={typeof(msg.seen) == 'number'?new Date(msg.seen).toLocaleString("tr-TR"):'-'}>
                                    <DoneAllIcon fontSize='13' /></Tooltip>}{msg.uploaded == false && <AccessTimeIcon fontSize='13' />} {msg.note && <LockIcon fontSize='13' />} <Tooltip title={new Date(msg.d).toLocaleString("tr-TR")}>{zeroPad(new Date(msg.d).getHours())+":"+zeroPad(new Date(msg.d).getMinutes())}</Tooltip></Typography>
                                
                                

                                    
                            {msg.p && !msg.deleted && !msg.old && <Button onClick={() => {
                                global.openPrompt("Mesajı sil", "", "Sil", () => {
                                    msg.deleted = true;
                                    global.showLoadingPanel();

                                    API.fetch("deletemsg", {_id: this.state.openedChat._id, index: i}).then(e => {
                                        global.hideLoadingPanel();
                                    })
                                })
                                
                            }} style={{position:"absolute",top:5, right:5}} color='error'>X</Button>}
                        </div>

                        
                    </div>)}
                </div>
                </div>
                            
                        <div className='warningsDiv'>

                            {this.state.openedChat.supportObject.events != undefined && this.state.openedChat.supportObject.events.filter(s => s.timeout >= Date.now()).map((x, i) => <p key={i}>{x.event}</p>)}
                                    

                        </div>

                        
                <div style={{backgroundColor:"#F7F7F7", position:"relative"}}>
                <div className='replyPrompt'>
                            
                            {this.state.openedChat.supportObject.value != undefined && this.state.openedChat.supportObject.value.startsWith("/") && this.state.replies.filter(s => {
                                
                                
                                return supportRegex.test(s.code,'i')
                            }).map((x, i) => <p onClick={() => {
                                this.state.openedChat.supportObject.value = x.msg;
                                this.forceUpdate();
                            }} key={i}><strong>/{x.code}</strong>{"     - " + x.msg}</p>)}
                                    

                        </div>

                    

                    <Button onClick={() => {this.state.openedChat.supportObject.note = false; this.forceUpdate()}} style={{backgroundColor:(!this.state.openedChat.supportObject.note)?"white":"#F7F7F7"}}>Cevapla</Button>
                    <Button onClick={() => {this.state.openedChat.supportObject.note = true; this.forceUpdate()}} style={{backgroundColor:(!this.state.openedChat.supportObject.note)?"#F7F7F7":"#FEFDE8"}}>Özel Not</Button>
                    </div>

                    {this.altMessageOnLoad != undefined && <div style={{width:"100%", padding:10,backgroundColor:"lightgray",borderTopLeftRadius:15, borderTopRightRadius:15}}>
                    <Typography style={{fontSize:13, fontWeight:"bold"}}>{this.altMessageOnLoad.text}</Typography></div>}
                <div style={{display:"flex", padding:"12px",position:"relative", backgroundColor:(this.state.openedChat.supportObject.note)?"#FEFDE8":"white",borderTop:"1px solid #E6E8F0"}}>
                            
                            
                    {(this.state.openedChat.supportObject.assignedWorkerId == API.user.id || this.state.openedChat.supportObject.note == true || this.state.openedChat._id == this.state.openedChat.clientId) && <form ref={(e) => this.chatFormRef = e} style={{display:"flex",flex:1,flexDirection:"row"}} onSubmit={async (e) => {
                        e.preventDefault();
                        
                        if(this.state.openedChat.supportObject.value.trim() != '' || this.state.openedChat.supportObject.files != undefined) {
                            var msg = this.state.openedChat.supportObject.value;

                         
                            
                           
                         

                            
                            this.setTyping(this.state.openedChat._id, true);

                            const chatToSend = this.state.openedChat;

                            var messagesToSend = [];
                            
                            if(this.state.openedChat.supportObject.files != undefined) {
                                for(var i = 0; i < this.state.openedChat.supportObject.files.length; i++) {
                                    const base64 = await blobToBase64(this.state.openedChat.supportObject.files[i].blob);

                                    

                                    
                                    var messageObj = {d: Date.now(), p: true, note: this.state.openedChat.supportObject.note,altMessage: this.altMessageOnLoad, img: this.state.openedChat.supportObject.files[i].url, uploaded: false};
                                    messagesToSend.push({img: base64, obj: messageObj});
                                    this.state.openedChat.liveSupport.messages.push(messageObj);
        
                                    this.state.openedChat.supportObject.lastMessage = messageObj;
                                }
                                

                            }

                            if(this.state.openedChat.supportObject.value.trim() != '') {
                                
                                var messageObj = {d: Date.now(), m: msg, p: true, note: this.state.openedChat.supportObject.note,altMessage: this.altMessageOnLoad, uploaded: false};
                                messagesToSend.push({msg, obj: messageObj});
                                this.state.openedChat.liveSupport.messages.push(messageObj);
    
                                this.state.openedChat.supportObject.lastMessage = messageObj;
                            }

                            this.state.openedChat.supportObject.value = "";
                            this.state.openedChat.supportObject.files = undefined;

                            this.forceUpdate();

                            setTimeout(() => {
                                this.setState(animateScroll.scrollToBottom({
                                    containerId: "messagesContainer",
                                    duration:10
                                  }));
                            }, 100);

                            
                            for(var i = 0; i < messagesToSend.length; i++) {

                               
                                const msgToSend = messagesToSend[i];
                                var e = await API.fetch("chat",{_id: chatToSend._id,files:chatToSend.supportObject.files,note: chatToSend.supportObject.note, msg: msgToSend.msg, img: msgToSend.img, p: chatToSend.p, altMessage: this.altMessageOnLoad});
                                delete this.altMessageOnLoad;
                                if(e._id != null) {
                                    this.state.support.find((s) => s._id == chatToSend._id)._id = e._id;
                                    chatToSend._id = e._id;
                                    
                                }

                                msgToSend.obj.uploaded = true;

                                this.forceUpdate();
    
                                    
                                
                            }


                            

                           
                            return;

                            API.fetch("chat",{_id: this.state.openedChat._id,files:this.state.openedChat.supportObject.files,note: this.state.openedChat.supportObject.note, msg, p: this.state.openedChat.p, altMessage: this.altMessageOnLoad}).then(e => {
                                delete this.altMessageOnLoad;
                                if(e._id != null) {
                                    this.state.support.find((s) => s._id == this.state.openedChat._id)._id = e._id;
                                    this.state.openedChat._id = e._id;
                                    
                                }
                                this.forceUpdate();

                                
                            })

                           
                        }

                        //this.forceUpdate();

                        
                        
                    }}>
                        
                        <div className='fileUploadDeleteRoot' style={{display:"flex",flex:1, flexDirection:"column"}}>                      
                        {this.state.openedChat?.supportObject?.files != undefined && <div style={{marginBottom:10,display:"flex",gap:10}}>

                            {this.state.openedChat.supportObject.files.map((x, i) => <div style={{width:70, height: 70, boxShadow:"0px 0px 10px -5px rgba(0,0,0,0.75)", position:"relative", borderRadius:15}} key={i}>
                        <img key={i} style={{width:70, height:70, objectFit:"contain",borderRadius:15}} src={x.url} />


                        <div onClick={() => {
                            this.state.openedChat.supportObject.files.splice(i, 1);
                            if(this.state.openedChat.supportObject.files.length <= 0) this.state.openedChat.supportObject.files = undefined;

                            this.forceUpdate();
                        }} className='fileUploadDelete' style={{ top:0,bottom:0,left:0,right:0,borderRadius:15}}><p>X</p></div>
                            </div>)}
                        </div>}

                        
                        
                        <TextareaAutosize onPaste={async (a) => {

                            const clipboardItems = await navigator.clipboard.read()

                            if(clipboardItems.length > 0 && clipboardItems.find(s => s.types.includes("image/png"))) {
                                const blobOutput = await clipboardItems[0].getType('image/png')
                                
                                if(blobOutput) {
                                    const data = URL.createObjectURL(blobOutput)
                              

                                    if(this.state.openedChat.supportObject.files == undefined) this.state.openedChat.supportObject.files = [];
                                    this.state.openedChat.supportObject.files.push({blob: blobOutput, url: data});
                                    this.forceUpdate();
                                }
                            }
                           
                          
                        }} data-enable-grammarly="false" data-gramm="false" data-gramm_editor="false"  onKeyDown={(e) => {
                            
                            if((e.metaKey || e.ctrlKey) && e.keyCode == 13)
                                this.chatFormRef.requestSubmit();
                        }} className='inputTextArea' autoComplete={"false"} value={this.state.openedChat.supportObject.value} onChange={(a) => {
                            this.state.openedChat.supportObject.value = a.target.value; 
                            this.forceUpdate();
                            //this.socket.emit("broadcast", "typing", API.user.id, API.user.name, this.state.openedChat._id, this.state.openedChat.supportObject.value.length <= 0);
                            this.setTyping(this.state.openedChat._id, this.state.openedChat.supportObject.value.length <= 0);
                            
                        }} autoFocus={true} sx={{width:"100%"}} fullWidth style={{width:"100%",backgroundColor:(this.state.openedChat.supportObject.note)?"#FEFDE8":"white"}} draggable={false} placeholder='Mesaj yaz' name='message'/>

</div> 
                            {this.state.openedChat.supportObject.aiResponseLoading != true && <a style={{cursor:"pointer"}} onClick={() => {
                                this.state.openedChat.supportObject.aiResponseLoading = true;
                                this.forceUpdate();

                                const currId = this.state.openedChat._id;
                                API.fetch("conversationsuggestion", {_id: this.state.openedChat._id}).then(e => {


                                    
                                    // if(e.prompt) {

                                    //     try {
                                    //     fetch('https://panel.tasimacim.com/api/suggestion', {body: JSON.stringify({prompt: e.prompt}), method:"POST",headers: {'Content-Type': 'application/json'}}).then(e => {
                                            
                                    //     try {
                                    //     return e.json()
                                    //     } catch {
                                    //         this.state.openedChat.supportObject.aiResponseLoading = false;
                                    //         this.forceUpdate();
                                    //     }
                                        
                                    //     }).catch(err => {
                                    //         console.error(err);


                                    //         this.state.openedChat.supportObject.aiResponseLoading = false;
                                    //         this.forceUpdate();


                                    //     }).then(x => {
                                         

                                    //         if(this.state.openedChat._id == currId) {

                                    //             if(x && x.text)
                                    //                 this.state.openedChat.supportObject.value = x.text;
                                    //             this.state.openedChat.supportObject.aiResponseLoading = false;
                                    //             this.forceUpdate();

                                    //         }
                                    //     })
                                    // } catch {
                                    //     if(this.state.openedChat._id == currId) {
                                            
                                    //         this.state.openedChat.supportObject.aiResponseLoading = false;
                                    //         this.forceUpdate();

                                    //     }
                                    // }
                                    // }
                                    if(this.state.openedChat._id == currId && e.text != undefined) {
                                        this.state.openedChat.supportObject.value = e.text;
                                        this.state.openedChat.supportObject.aiResponseLoading = false;
                                        this.forceUpdate();

                                    }
                                })
                                
                            }}><AutoAwesomeIcon   color='#AD6EB1' style={{position:"absolute", right:150, bottom:22, color: "#AD6EB1"}} /></a>}
                            {this.state.openedChat.supportObject.aiResponseLoading == true && <CircularProgress  style={{position:"absolute", right: 145, marginTop:6}} size={30} />}


                            <div onClick={(e) => e.preventDefault()} className={'fileUploaderMain'+  (this.state.draggingFile?" upload":"")}>
                            <FileUploader multiple children={<div style={{justifyContent:"center", alignItems:"center", width:"100%", height:"100%",display:"flex"}}>
                                <AttachFileIcon fontSize="medium" style={{color: "#BEC2C7"}} />
                            </div>} onClick={(e) => e.preventDefault()}  onDraggingStateChange={(a) => {
                               
                            }} name="file" types={["JPG", "PNG", "JPEG"]} handleChange={(files) => {

                                for(var i = 0; i < files.length; i++) {
                                    var file = files[i];
                                    var data = URL.createObjectURL(file);
                                    if(this.state.openedChat.supportObject.files == undefined) this.state.openedChat.supportObject.files = [];
                                    this.state.openedChat.supportObject.files.push({blob: file, url: data});
                                }
                                
                               
                                

                                this.forceUpdate();
                        }} />
                        </div>
                        

                        <Button type='submit' disabled={this.state.openedChat.supportObject.value.trim() == '' && this.state.openedChat.supportObject.files == undefined} variant='contained' style={{marginLeft:12}}><SendIcon /></Button>
                    </form>}

                    {(this.state.openedChat.supportObject.assignedWorkerId != API.user.id && this.state.openedChat.supportObject.note != true && this.state.openedChat._id != this.state.openedChat.clientId) && <div style={{display:"flex",flex:1,flexDirection:"column", justifyContent:"center",textAlign:"center"}}>
                        
                        {this.state.openedChat.supportObject.assignedWorkerId == undefined && <Typography>Bu konuşma şu an kimse tarafından alınmamış</Typography>}
                        {this.state.openedChat.supportObject.assignedWorkerId != undefined && <Typography>Bu konuşmaya şu an {this.state.openedChat.supportObject.assignedWorkerName} bakıyor</Typography>}
                        <Button onClick={() => {
                            global.showLoadingPanel();
                            API.fetch("takeoverconversation", {_id: this.state.openedChat._id}).then(e => {
                                global.hideLoadingPanel();
                            })
                        }}>Üstüne Al</Button>
                    </div>}
                    
                </div>
                
            </div>}

            {/* <SipProvider ref={(a) => {
                this.a = a
                
            //     if(a != null) {setTimeout(() => {
            //         a.startCall("+9053627343 68");
            //     }, 5000);
            //     setInterval(() => {
            //         console.log(a.state);
            //     }, 1000);
            // }
            }}
            host="sp.sanal.link"
            port={7443}
            pathname="/wss" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
            secure={true} // if true, the connection will be made over `wss://` else it will default to `ws://`
            user="908504415115"
            password="CURPePaZ7737" // usually required (e.g. from ENV or props)
            autoRegister={true} // true by default, see jssip.UA option register
            autoAnswer={false} // automatically answer incoming calls; false by default
            sessionTimersExpires={120} // value for Session-Expires header; 120 by default
            extraHeaders={{ // optional sip headers to send
              register: ['X-Foo: foo', 'X-Bar: bar'],
              invite: ['X-Foo: foo2', 'X-Bar: bar2']
            }}
            iceServers={[ // optional
              { urls: ['stun:a.example.com', 'stun:b.example.com'] },
              { urls: 'turn:example.com', username: '908504415115', credential: 'CURPePaZ7737' }
            ]}
            debug={true} // whether to output events to console; false by default
            inboundAudioDeviceId={"default"} // default, or a deviceId obtained from navigator.mediaDevices.enumerateDevices() where kind is "audioinput"
            outboundAudioDeviceId={"default"} 
  >
        
  </SipProvider> */}
        </div>
    }
}
function diacriticSensitiveRegex(string = '') {
    return '' + (string.replace(/\+/g,'').replace(/[Iİıi]/g, '[I,İ,ı,i]').replace(/[öÖoO]/g, '[ö,Ö,o,O]').replace(/[şŞsS]/g, '[ş,Ş,s,S]').replace(/[çÇcC]/g, '[ç,Ç,c,C]').replace(/[ğĞgG]/g, '[ğ,Ğ,g,G]').replace(/[uUÜü]/g, '[u,U,Ü,ü]')).split(/\,|\s/).join(' ') + ''
   }
   

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

const zeroPad = (num, places = 2) => String(num).padStart(places, '0')

export default withRouter(LiveSupportScreen)