import React from 'react';
import './NavBar.css';

import {ListItem, Button, Typography} from '@mui/material';

import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import MenuIcon from '@mui/icons-material/Menu';
import API from '../API';
import SearchIcon from '@mui/icons-material/Search';

export default class NavBarLayout extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {sideBarOpened: false};

        this.navigationPossible = [];

        var isAdmin = API.user.permissions.includes(0);
        for(var i = 0; i < API.NAVIGATIONS.length; i++) {
            if(API.NAVIGATIONS[i].title) {

                var found = false;
                for(var x = i+1; x < API.NAVIGATIONS.length; x++) {
                    if(API.NAVIGATIONS[x].title) break;

                    if(isAdmin || API.user.permissions.includes(API.NAVIGATIONS[x].permission)) {found = true; break;}
                }

                if(found) this.navigationPossible.push(API.NAVIGATIONS[i]);
                continue;
            }

            if(isAdmin || API.user.permissions.includes(API.NAVIGATIONS[i].permission)) {
                this.navigationPossible.push(API.NAVIGATIONS[i]);
            }
        }
        


    }

    
    render() {
        var style = {};
        if(this.props.nopadding) style.padding = "0px";
        
        var v = this.props.history.location.pathname;
        return <div className='navBarRoot'>
            <div onClick={() => this.setState({sideBarOpened: false})} className={'sideBarBackground ' + (this.state.sideBarOpened?"open":"")}></div>
            <div className={'sideBarRoot ' + (this.state.sideBarOpened?"open":"")}>
                {this.navigationPossible.map((x, i) => {
                    if(x.title) return <Typography key={i} style={{fontWeight:"700", color:"#6B7280", marginLeft:25,marginTop:50,fontSize:14}}>{x.title}</Typography>

                    if(x.menu) return <SidebarMenuItem onClick={() => this.setState({sideBarOpened: false})} key={i} nav={x} selected={v} />
                    return <SidebarItem onClick={() => this.setState({sideBarOpened: false})} selected={v == x.href} key={i} nav={x} />
                })}
            </div>
            <div className='contentRoot'>
            <div className='contentMain' style={style}>
                    {this.props.children}
                </div>
                <div className='topBar'>
                    <div style={{marginRight:20}}><SearchIcon sx={{fill: "#6B7280"}} onClick={() =>  global.openSearch()} /></div>
                    <div className='navBarMenu'><MenuIcon sx={{fill: "#6B7280"}} onClick={() =>  {
                        if(global.clickMenuButton != undefined && !global.clickMenuButton())return;
                        this.setState({sideBarOpened: !this.state.sideBarOpened})
                    
                    }} /></div>
                </div>
                
            </div>
        </div>
    }
}

class SidebarMenuItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {expanded: false}
    }

    render() {
        var isSelected = this.props.nav.menu.find((s) => s.href == this.props.selected);

        return <div className={'sideBarItemRoot' + ((isSelected)?"selected":"")} style={{paddingLeft:"16px", paddingRight:"16px"}}><div onClick={() => {
            this.setState({expanded: !this.state.expanded})
        }} style={{alignItems:"flex-start", textDecoration:"none", justifyContent:"space-between"}} className={'sideBarItem' + ((isSelected)?"selected":"")}>
            
            <div style={{flexDirection:"row",display:"flex"}}>
            {this.props.nav.icon}
            <Typography>{this.props.nav.name}</Typography>
            </div>

            {this.state.expanded && <ExpandMoreIcon style={{marginRight:16}} />}
            {!this.state.expanded && <ChevronRightIcon style={{marginRight:16}} />}
    
        </div>
            <div className={'sidemenuBar '+((this.state.expanded)?"extended":"")}>
                
                {this.props.nav.menu.map((link,i) => <SidebarItem onClick={() => props.onClick()} key={i} nav={link} selected={this.props.selected == link.href} />)}
            </div>
        </div>
    }
} 

const SidebarItem = (props) => {
    
    return <div className={'sideBarItemRoot '+(props.selected?"selected":"")} style={{paddingLeft:"16px", paddingRight:"16px"}}><Link onClick={() => props.onClick()} to={props.nav.href} style={{alignItems:"flex-start", textDecoration:"none"}} className={'sideBarItem'+(props.selected?" selected":"")}>
        
        {props.nav.icon}
        <Typography>{props.nav.name}</Typography>


    </Link></div>
}

