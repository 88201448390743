import { Typography, Avatar, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import CircleIcon from '@mui/icons-material/Circle';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ErrorIcon from '@mui/icons-material/Error';
import { DataGrid } from '@mui/x-data-grid';
import "./LogisticsScreen.css";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

var types =["Motor", "Sedan","Doblo", "Minivan","Panelvan","Kamyonet"]

export default class DriversScreen extends React.Component {

    constructor() {
        super();

        this.state = {drivers: [],cityFilter: [6,34,35], driverCount: 0};
    }

    componentDidMount() {
       this.getDriverList();
       
    }

    getDriverList(page) {
        API.fetch("getdrivers",{page, cityFilter: this.state.cityFilter}).then((e) => {
            
            if(e.driverCount) this.state.driverCount = e.driverCount;

            
            this.setState({drivers: e.drivers});
        })
    }
    render() {
        return <div>


            
<div>
            <FormGroup style={{display:"flex", flexDirection:"row"}}>
            <FormControlLabel control={<Checkbox onChange={(e) => { if(e.target.checked) this.state.cityFilter.push(6); else this.state.cityFilter.splice(this.state.cityFilter.indexOf(6), 1); this.getDriverList() }} defaultChecked />} label="Ankara" />
            <FormControlLabel control={<Checkbox onChange={(e) => { if(e.target.checked) this.state.cityFilter.push(34); else this.state.cityFilter.splice(this.state.cityFilter.indexOf(34), 1); this.getDriverList()}} defaultChecked />} label="İstanbul" />
  <FormControlLabel control={<Checkbox onChange={(e) => { if(e.target.checked) this.state.cityFilter.push(35); else this.state.cityFilter.splice(this.state.cityFilter.indexOf(35), 1); this.getDriverList()}} defaultChecked />} label="İzmir" />
</FormGroup>
            </div>


            <Badge style={{marginTop:50,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h5'>Sürücü Listesi</Typography>
                
                </div>
            <DataGrid
                autoHeight
                rows={this.state.drivers.map((x, i) => ({...x, id: i}))}
                columns={[{field: "Ad Soyad",flex:1, renderCell: (params) => <div style={{display:"flex", alignItems:"center"}}>
                    <Avatar src={"https://ik.imagekit.io/tasimacim/driver/tr:w-80/"+params.row._id+".jpeg"} /> {/*src={"https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/"+params.row._id+".jpeg"}*/}
                    <div style={{marginLeft:10}}>
                        <Typography variant="body1">{params.row.name}</Typography>
                        <Typography variant="body2">{params.row.email}</Typography>
                    </div>
                </div>}, {field: "Araç", flex:1, valueGetter: (params,row) => row.vehicles.map((x) => types[x.type]).join(",")},{field: "Şehir", flex:1, valueGetter: (params,row) => API.getCityName(row.city)},{field: "Telefon", flex:1, valueGetter: (params,row) => row.phone},{field: " ", renderCell: (params) => {
                    return <a href={"../driver/" + params.row._id}><Button><ArrowForwardIcon /></Button></a>
                    // return <Link to={'/driver/' + params.row._id} ><Button><ArrowForwardIcon /></Button></Link>
                }}]}
                

                initialState={{pagination: {paginationModel: {pageSize: 10, page: 0}}}}
                onPaginationModelChange={(model) => {
                    // this.setState({drivers: []});
                    this.getDriverList(model.page);
                }}
                pageSizeOptions={[50]}
                slotProps={{pagination: {showFirstButton: true, showLastButton: true}}}

                rowCount={this.state.driverCount}
                paginationMode='server'
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge>
        </div>
    }
}

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}
