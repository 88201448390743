import { Grid, Typography, Button, CircularProgress, Checkbox, FormControlLabel, Popper, Fade, TextField, FormControl, OutlinedInput, InputAdornment, InputLabel, FormGroup, Radio, FormLabel, RadioGroup } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import CircleIcon from '@mui/icons-material/Circle';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ErrorIcon from '@mui/icons-material/Error';
import { DataGrid } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./LogisticsScreen.css";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Link } from 'react-router-dom';
import API from '../API';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/system';
import SearchBarComponent from '../components/SearchBarComponent';
import { CheckBox } from '@mui/icons-material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

const shortcutsItems = [
  {
      label: 'Bugün',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('day'), today.endOf('day')];
      },
    },
  {
    label: 'Bu Hafta',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Geçen Hafta',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Son 7 Gün',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Bu Ay',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  { label: 'Her zaman', getValue: () => [null, null] },
];

export default class FinanceScreen extends React.Component {

    constructor() {
        super();

        this.state = {analytics: [],orders: [],orderCount: 0, selectedDateRange: 0, latestTransactionsCount:0,balanceActionsOpen: false,balanceValue: "",balanceTransactionType: 0,balanceDescription: "", selectedDriver: undefined,shouldBeBonus: false, completeOnly: false};
    }

    
    componentDidMount() {
        
        this.updateAnalytics([]);

        this.getInvoiceRequired();

        if(API.user && API.user.permissions.includes(0))
          this.getPage(0);
    }

    getAnalytics(range,completeOnly) {
        console.log(range, completeOnly);
        this.setState({analytics: []});
        if(range == undefined) range = this.state.selectedDateRange;
        if(completeOnly == undefined) completeOnly = this.state.completeOnly;

       
        API.fetch("getfinance", {range,completeOnly}).then((e) => {
            var analytics = e.analytics;

            var total = 0;
            var commision = 0;

            e.graph.forEach((s) => {
                total += s.volume;
                commision += s.commision;
            })

            analytics[0] = total;
            analytics[1] = commision;


            var v = 0;
            var transactions = analytics[3];
            analytics[3].forEach((s) => v -= s.value);
            analytics[3] = v;

            
            

            this.setState({analytics: e.analytics,graph: e.graph,transactions});


        })
    }

    getPage(page) {
      // this.setState({latestTransactions: undefined});

      API.fetch("getlatesttransactions", {page}).then(e => {
        if(e.count) this.state.latestTransactionsCount = e.count;
        this.setState({latestTransactions: e.transactions});
        console.log(e);
      })
    }

    getInvoiceRequired() {
      API.fetch("getinvoicerequiredorders").then(e => {
      
        console.log(e);
        this.setState({invoiceRequiredOrders: e});
      })
    }

    updateAnalytics(range) {
        if((range[0] == null && range[1] == null) || (range[0] != null && range[1] != null)) {
            this.setState({analytics: []});

            var filter = {status: {$ne: -1}};
            if(range[0] != null) {
                var firstRange = new Date(range[0].$d);

                firstRange.setHours(0,0,0);

                var secRange = new Date(range[1].$d);
                secRange.setHours(23,59,59);
                filter._id = {$gte: objectIdFromDate(firstRange), $lte: objectIdFromDate(secRange)};


                
            }

            

            
            API.fetch("getfinance", {filter}).then((e) => {
                var analytics = e.analytics;
    
                var total = 0;
                var commision = 0;
    
                e.graph.forEach((s) => {
                    total += s.volume;
                    commision += s.commision;
                })
    
                analytics[0] = total;
                analytics[1] = commision;
    
    
                var v = 0;
                var transactions = analytics[3];
                analytics[3].forEach((s) => v -= s.value);
                analytics[3] = v;
    
                
    
                this.setState({analytics: e.analytics,graph: e.graph,transactions});
    
    
            })
        }
    }
    render() {
        return <div>

            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginBottom: 30, alignItems:"flex-end"}}>

                
           
            <Typography variant='h4'>Finans</Typography>

<div style={{display:"flex"}}>
       
        {/* <FormControlLabel control={<Checkbox value={this.state.completeOnly} onChange={(e,checked) => {
            this.setState({completeOnly: checked});
            
            this.getAnalytics(undefined, checked);
        }} />} label="Sadece Tamamlanmış"  />
            <ToggleButtonGroup
      value={this.state.selectedDateRange}
      exclusive
      onChange={(a) => {
        this.setState({selectedDateRange: parseInt(a.currentTarget.value)});
        this.getAnalytics(parseInt(a.currentTarget.value));
      }}
      aria-label="text alignment"
    >
      <ToggleButton value={0} aria-label="left aligned">
        <Typography>Bugün</Typography>
      </ToggleButton>
      <ToggleButton value={1} aria-label="centered">
      <Typography>Bu Hafta</Typography>
      </ToggleButton>
      <ToggleButton value={2} aria-label="right aligned">
      <Typography>Bu Ay</Typography>
      </ToggleButton>
      <ToggleButton value={3} aria-label="right aligned">
      <Typography>Tüm</Typography>
      </ToggleButton>
    </ToggleButtonGroup> */}

<LocalizationProvider dateAdapter={AdapterDayjs}>
<DateRangePicker onAccept={(rangePosition) => {
    this.updateAnalytics(rangePosition);


}} slotProps={{shortcuts: {items: shortcutsItems}, actionBar: {actions: []}}} slots={{field: SingleInputDateRangeField}} localeText={{ start: 'Check-in', end: 'Check-out' }} />
</LocalizationProvider>




    </div>
 </div>
            {/* <div style={{flexDirection:"row", display:"flex",flexWrap:"wrap"}}> */}
            <Grid spacing={"12px"} container>
                <LogisticAnalytic first icon={<CircleIcon sx={{color: 'rgb(209, 67, 67)'}} />} backgroundColor={"rgba(209, 67, 67, 0.1)"} title={"Toplam hacim"} value={this.state.analytics[0]} />
                <LogisticAnalytic icon={<DoneAllIcon sx={{color: 'rgb(134,197,82)'}} />} backgroundColor={"rgba(134,197,82,.1)"} title={"Toplam komisyon"} value={this.state.analytics[1]} />
                {/* <LogisticAnalytic icon={<WarningIcon sx={{color: 'rgb(209, 67, 67)'}} />} backgroundColor={"rgba(209, 67, 67, 0.1)"} title={"Hata bildirimleri"} value={"38"} />
                 */}
                <LogisticAnalytic icon={<ErrorIcon sx={{color: 'rgb(255,176,32)'}} />} backgroundColor={"rgba(255,176,32,.1)"} title={"Sürücü bakiyeleri"} value={this.state.analytics[2]?(this.state.analytics[2].freeCredits+this.state.analytics[2].credits).toFixed(2) +"₺ (" + this.state.analytics[2].credits.toFixed(2) + "₺)":undefined} />
                
                <LogisticAnalytic icon={<QueryBuilderIcon sx={{color: 'rgb(80,72,229)'}} />} backgroundColor={"rgba(80,72,229,.1)"} title={"Çekme talep edilen bakiye"} value={this.state.analytics[3]} />
            </Grid>
            {/* </div> */}

            <Button onClick={() => {
                this.setState({balanceActionsOpen: !this.state.balanceActionsOpen})
                if(!this.state.balanceActionsOpen) this.setState({balanceValue: "",balanceTransactionType: 0,balanceDescription: "", selectedDriver: undefined,shouldBeBonus: false})
            }} ref={a => this.balanceActionsButtonRef = a}>Talep Oluştur</Button>
<Popper  open={this.state.balanceActionsOpen} anchorEl={this.balanceActionsButtonRef} transition>
  {({ TransitionProps }) => (
    <Fade {...TransitionProps} timeout={350}>
      <Box sx={{ border: 1, p: 3, bgcolor: 'background.paper' }}>

      {this.state.selectedDriver == undefined && <><Typography style={{marginBottom:10}}>Sürücü</Typography>
        <SearchBarComponent onValueSelected={(v) => {
            if(!v) return;
            
            this.setState({selectedDriver: v});
        }} search={{drivers: true}} /></>}
        {this.state.selectedDriver != undefined && <div style={{display:"flex", gap:10, alignItems:"center"}}>
        
            <Typography>{this.state.selectedDriver.label}</Typography>
            <Button onClick={() => this.setState({selectedDriver: undefined})}>X</Button>
        </div>}

        <FormControl style={{marginTop:20}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Tip</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={this.state.balanceTransactionType}
      >
        <FormControlLabel onClick={() => this.setState({balanceTransactionType: 0})} value={0} control={<Radio />} label="Para Yatır" />
        <FormControlLabel onClick={() => this.setState({balanceTransactionType: 1})} value={1} control={<Radio />} label="Para Çek" />
        
      </RadioGroup>
    </FormControl>

    <FormGroup>
  <FormControlLabel checked={this.state.shouldBeBonus}  control={<Checkbox onChange={() => this.setState({shouldBeBonus: !this.state.shouldBeBonus})}  />} label={this.state.balanceTransactionType == 0?"Bonus yatır":"Bonusdan da kullan"} />
  
</FormGroup>
        

        

        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <InputLabel htmlFor="outlined-adornment-amount">Miktar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={this.state.balanceValue}
            onChange={(e) => this.setState({balanceValue: e.target.value})}
            startAdornment={<InputAdornment position="start">TRY</InputAdornment>}
            label="Amount"
          />
        </FormControl>


        
        <FormControl fullWidth sx={{marginTop:3}}>
          <InputLabel htmlFor="outlined-adornment-amount-ds">Açıklama</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount-ds"
            value={this.state.balanceDescription}
            onChange={(e) => this.setState({balanceDescription: e.target.value})}
            
            label="Amount"
          />
        </FormControl>
        

        <Button onClick={() => {
            if(!this.state.balanceDescription || !this.state.selectedDriver || this.state.balanceTransactionType == undefined) return;

            global.openPrompt("Bakiye işlemini gerçekleştirmek istediğinize emin misiniz?","Bu işlem onaylandıktan sonra girmiş olduğunuz bakiye otomatik olarak sürücünün hesabında işlem görücektir","Onayla", () => {
                global.showLoadingPanel();

                API.fetch("createtransaction", {_id: this.state.selectedDriver._id,description: this.state.balanceDescription, amount: this.state.balanceValue, bonus: this.state.shouldBeBonus, balanceTransactionType: this.state.balanceTransactionType}).then((e) => {
                    
                    if(e.error) return;
                    global.hideLoadingPanel();

                    this.setState({balanceActionsOpen: false});
                    


                })
            })
        }} fullWidth variant='contained' style={{marginTop:20}}>Yolla</Button>
        
        
      </Box>
    </Fade>
  )}
</Popper>
            <Badge style={{marginTop:50,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Para Çekme Talepleri</Typography>
                
                </div>
            {this.state.transactions != undefined && <DataGrid
                autoHeight
                rows={this.state.transactions.map((x, i) => ({...x, id: Math.random()}))}
                columns={[{field: "Tarih",flex:1, valueGetter: (params,row) => {return new Date(parseInt(row._id.substring(0, 8), 16) * 1000).toLocaleString('tr-TR')}},{field: "Sürücü",flex:1, valueGetter: (params,row) => {return row.receiverName;}},{field: "IBAN",flex:1, valueGetter: (params,row) => row.receiverIBAN},{field: "Tutar", flex:1, valueGetter: (params,row) => (-row.value).toFixed(2)+"₺"},{field: "  ", renderCell: (params) => {
                    return <Button onClick={() => {
                        global.openPrompt("Para çekme talebini iptal etmek istediğinize emin misiniz?", "Bu adımı onayladığınız zaman sürücünün hesabına para geri yatırılacaktır", "Onayla", () => {
                            global.showLoadingPanel();

                            API.fetch("cancelwithdrawrequest", {_id: params.row._id}).then((e) => {

                                this.state.transactions.splice(this.state.transactions.findIndex(s => s._id == params.row._id),1);
                                global.hideLoadingPanel();
                                this.forceUpdate();
                            })
                        })
                    }} variant='contained' color='error'>Iptal</Button>
                }},{field: "   ", renderCell: (params) => {
                    return <Button onClick={() => {
                        global.openPrompt("Para çekme talebini onaylamak istediğinize emin misiniz?", "Bu adımı onayladığınız zaman sürücünün IBAN ına paranın gitmiş olduğundan emin olun", "Onayla", () => {
                            global.showLoadingPanel();

                            API.fetch("approvewithdrawrequest", {_id: params.row._id}).then((e) => {

                                this.state.transactions.splice(this.state.transactions.findIndex(s => s._id == params.row._id),1);
                                global.hideLoadingPanel();
                                this.forceUpdate();
                            })
                        })
                    }} variant='contained'>Onayla</Button>
                }},{field: " ", renderCell: (params) => {
                    
                    return <Link to={'/driver/' + params.row.receiver} ><Button><ArrowForwardIcon /></Button></Link>
                }}]}
                pageSize={10}
                rowsPerPageOptions={[10]}
                onPageChange={(page) => {
                    this.setState({orders: []});
                    this.getOrders(page);
                }}
                
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />}
            {this.state.transactions == undefined && <CircularProgress />}
            </Badge>

            
            <Badge style={{marginTop:50,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Fatura Bekleyen İşlemler</Typography>
                
                </div>
            {this.state.invoiceRequiredOrders != undefined && <DataGrid
            getRowId={(row) => row._id}
                autoHeight
               
                rows={this.state.invoiceRequiredOrders}
                columns={[{field: "Sipariş Tarihi",flex:0.2, valueGetter: (params,row) => {return new Date(parseInt(row._id.substring(0, 8), 16) * 1000).toLocaleString("tr-TR")}},{field: "Sürücü",flex:.3, renderCell: (params) => <Link to={'/driver/'+params.row.driver._id}>{params.row.driver.name}</Link>},,{field: " ", renderCell: (params) => {
                    
                  return <Link to={'/order/' + params.row._id} ><Button><ArrowForwardIcon /></Button></Link>
              }}]}
                

                initialState={{pagination: {paginationModel: {pageSize: 10, page: 0}}}}
                onPaginationModelChange={(model) => {
                  this.getPage(model.page)
                }}
                pageSizeOptions={[10]}
                getCellClassName={(param) => param.row.status == -1?"cellTransactionCancelled":""}
                paginationMode='client'
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />}
            </Badge>

            {API.user.permissions.includes(0) && <Badge style={{marginTop:50,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Son İşlemler</Typography>
                
                </div>
            {this.state.latestTransactions != undefined && <DataGrid
            getRowId={(row) => row._id}
                autoHeight
               
                rows={this.state.latestTransactions}
                columns={[{field: "Tarih",flex:0.2, valueGetter: (params,row) => {return new Date(parseInt(row._id.substring(0, 8), 16) * 1000).toLocaleString("tr-TR")}},{field: "Sürücü",flex:.3, renderCell: (params) => <Link to={'/driver/'+params.row.driver._id}>{params.row.driver.name}</Link>},{field: "Tür",flex:1, valueGetter: (params,row) => {
                    
                    return API.getTransactionDescription(row)
                }},{field: "Tutar", flex:0, valueGetter: (params,row) => row.value.toFixed(2)+ "₺"}]}
                

                initialState={{pagination: {paginationModel: {pageSize: 10, page: 0}}}}
                onPaginationModelChange={(model) => {
                  this.getPage(model.page)
                }}
                pageSizeOptions={[10]}
                getCellClassName={(param) => param.row.status == -1?"cellTransactionCancelled":""}
                paginationMode='server'
                rowCount={this.state.latestTransactionsCount}
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />}
            {this.state.latestTransactions == undefined && <CircularProgress />}
            </Badge>}
        </div>
    }
}

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}

const LogisticAnalytic = (props) => {
    return <Grid item xs={6} sm={3} ><Badge>
        <div style={{display:"flex", alignItems:"center"}}>
            <div style={{backgroundColor: props.backgroundColor, width:40, height:40, borderRadius:8, justifyContent:"center", alignItems:"center", display:"flex"}}>
                {props.icon}
            </div>

            {props.value != undefined && <Typography style={{marginLeft:10}} variant='h5'>{typeof props.value == 'number'?props.value.toFixed(2) + "₺":props.value}</Typography>}
            {props.value == undefined && <CircularProgress />}
        </div>
        <Typography style={{marginTop:15,color:"rgb(101, 116, 139)",fontWeight:"400"}} variant='body2'>{props.title}</Typography>
    </Badge>
    </Grid>
}


var objectIdFromDate = function (date) {
	return Math.floor(date.getTime() / 1000).toString(16) + "0000000000000000";
};