import { Rating, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';


export default class Review extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Badge style={{marginTop:24, padding:0, paddingBottom:24, paddingLeft:24, paddingRight:24}}>
                
                <div style={{display:"flex", flex:1,flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginTop:24,marginBottom:5}}>

                    <div style={{display:"flex", alignItems:"center",gap: 10}}>
                    <Typography  style={{}} variant='h6'>Müşteri Değerlendirmesi </Typography>
                    {this.props.review._id != undefined && <Link to={"../order/" + this.props.review._id}><Typography style={{}}>#{this.props.review._id.toString().substring(18).toUpperCase()}</Typography></Link>}
                    </div>


                    {(this.props.review.d != undefined || this.props.review._id != undefined) && <Typography style={{color:"gray"}}>{(this.props.review.d != null?new Date(this.props.review.d):dateFromObjectId(this.props.review._id)).toLocaleString("tr-TR")}</Typography>}
                </div>
                <div style={{display:"flex", alignItems:"center",gap: 10}}>
                    <Rating readOnly value={this.props.review.star} />

                    {this.props.review.tip && <Typography variant='subtitle1'>• {this.props.review.tip}₺ 💸</Typography>}
                </div>
                    

                <Typography style={{marginTop:5}} variant='body1'>{this.props.review.message}</Typography>


                
            </Badge>
    }
}


const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}


var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};