import { Autocomplete, TextField, Typography } from '@mui/material';
import React from 'react';
const Towns = require('../istanbultowns.json');
export default class TownSelectionInput extends React.Component {
    constructor() {
        super();
    }


    render() {
        return <div style={{position:"relative",display:"flex", paddingTop:10, paddingBottom:10,alignItems:"center", borderBottom:"solid 1px #E6E8F0", borderTop:(this.props.first?"solid 1px #E6E8F0":"")}}>
            <Typography style={{fontWeight:"600", marginLeft:24}}>{this.props.label}</Typography>
            
            
            <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={Towns.map((x, i) => ({label: x.name, id: i}))}
        sx={{ width: 300, position:"absolute", left:"30%" }}
        renderInput={(params) => <TextField {...params} label="İlçe" />}
      /></div>
    }
}