import React from 'react';
import { Typography, Button, Avatar, Menu,TextField, Select, Autocomplete , MenuItem,CircularProgress, Backdrop, Tooltip, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio, TextareaAutosize, FormControl } from '@mui/material';
import API from '../API';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "dayjs/locale/tr";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MuiTelInput } from 'mui-tel-input'
// const FILES = ["Sürücü Belgesi Ön", "Sürücü Belgesi Arka", "İkametgah Belgesi", "Adli Sicil Kaydı", "Profil Fotoğrafı"];
const VEHICLE_NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet"];
const VEHICLE_TIMES = [5,15,15,15,20,30]

var ADDITIONALS = ["Ön koltukta refakat", "Evcil hayvanım var", "Üstü kapalı kamyonet", "Sürücünün yardımı", "Yardımcı"]
const vehicle_imgs = [require('../Motor.png'),require('../Motor.png'),require('../Doblo.png'),require('../Motor.png'),require('../Panelvan.png'),require('../Kamyonet.png')]
export default class CreateOrderScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: true,editing: false, saving: false, calculating: false,phoneNumberLoading: false, approveDriverDialog: false, backdropVisible: false,actionsDropdownOpen: false,
        
        
        order: {
            date: 0,
            vehicle: undefined
        },
        phoneNumber: "",
        clientName: "",
        clientEmail: "",
        foundUserId: undefined,
        waypoints: [{},{}],

        
            // routes: [
            //     {
            //         "bridges": [
            //             {
            //                 "id": 0,
            //                 "price": 3.25
            //             }
            //         ],
            //         "waypointFee": 0,
            //         "traffic": false,
            //         "price": 1412,
            //         "time": 86
            //     },
            //     null,
            //     {
            //         "bridges": [
            //             {
            //                 "id": 0,
            //                 "price": 10.75
            //             }
            //         ],
            //         "waypointFee": 0,
            //         "traffic": false,
            //         "price": 2089,
            //         "time": 104
            //     },
            //     {
            //         "bridges": [
            //             {
            //                 "id": 0,
            //                 "price": 23.25
            //             }
            //         ],
            //         "waypointFee": 0,
            //         "traffic": false,
            //         "price": 952,
            //         "time": 114
            //     },
            //     {
            //         "bridges": [
            //             {
            //                 "id": 1,
            //                 "price": 23.25
            //             }
            //         ],
            //         "waypointFee": 0,
            //         "traffic": true,
            //         "price": 2952,
            //         "time": 111
            //     },
            //     {
            //         "bridges": [
            //             {
            //                 "id": 1,
            //                 "price": 23.25
            //             }
            //         ],
            //         "waypointFee": 0,
            //         "traffic": true,
            //         "price": 4050,
            //         "time": 131
            //     }
            // ],
            // "additionalPrices": [
            //     0,
            //     0,
            //     0,
            //     [
            //         null,
            //         null,
            //         300,
            //         null,
            //         700,
            //         700
            //     ],
            //     [
            //         null,
            //         null,
            //         600,
            //         null,
            //         700,
            //         700
            //     ]
            // ],
            // "motorcycleOpen": false
        
    };

        
        var id = props.match.params.id;

        
        // if(!id) props.history.goBack();

        this.state.loading = (id != undefined);

        
    }
    componentDidMount() {
        var id = this.props.match.params.id;
        
        console.log(id);
        if(id) {
            API.fetch("getwidgetcalculation", {_id: id}).then((e) => {
                
                console.log(e);
                var d = e.order.phone;
                d = d.substring(0,3) + " " + d.substring(3,6) + " " + d.substring(6,9) + " " + d.substring(9,11) + " " + d.substring(11,13);
                this.setState({routes: e.order.pricing, loading: false, waypoints: e.order.waypoints, additionalPrices: e.additionalPrices,phoneNumber: d, phoneNumberLoading: true});
                
            
           
                this.searchPhoneNumber(d);
            })
        }

        
        // API.fetch('getapplications').then((e) => {
        //     if(!e.error) this.setState({applications: e});
        // })
    }

    searchPhoneNumber(e) {
        API.fetch('findclientbyphone', {phone: e.trim().replace(/ /g, "")}).then(user => {

            console.log(e, user);
            if(this.state.phoneNumber != e) return;

            this.setState({phoneNumberLoading: false});

            if(user != null) {
                this.setState({foundUserId: user._id, clientName: user.name, clientEmail: user.email || "..."});
            } else {
                this.setState({foundUserId: undefined});
            }
        })
    }

    render() {

        if(this.state.loading) return <></>


      


        return <div style={{display:"flex", justifyContent:"center"}}>

            <div style={{maxWidth:900, width:"100%"}}>
            <div style={{marginTop:40, display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <div style={{display:"flex"}}>
                    {/* <Avatar sx={{width:70, height:70}} src={"https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/"+this.state.driver._id+".jpeg"} /> */}
                    <div style={{marginLeft:20}}>
                        <Typography variant='h4'>Sipariş #123</Typography>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <Typography variant='body2'>sipariş_tarihi: </Typography>
                            <div style={{marginLeft:10, backgroundColor:"#E5E7EB", borderRadius:50, paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2}}>
                                <Typography style={{fontWeight:"600"}} variant='body2'>{new Date().toLocaleString("tr-TR")}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                
{/* {!this.state.editing && <Button onClick={() => this.setState({approveDriverDialog: true})} variant='contained'>Başvuruyu Onayla</Button>} */}


                   


                   
                </div>

                
            </div>



                {/* <div style={{display:"flex",marginTop:24}}>
                    <LogisticAnalytic backgroundColor={'rgba(80,72,229,.1)'} first title={"Son 7 günde sipariş"} value={this.state.driver.orders.length} icon={<LocalShippingIcon sx={{color: 'rgb(80,72,229)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(134,197,82,.1)"} title={"Güncel bakiye"} value={(this.state.driver.credits+this.state.driver.freeCredits) +(this.state.driver.freeCredits > 0?" ("+this.state.driver.credits+")":"")} icon={<AccountBalanceWalletIcon sx={{color: 'rgb(134,197,82)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(255,176,32,.1)"} title={"7 günde iptal"} value={(this.state.driver.canceledOrderCount)} icon={<ErrorIcon sx={{color: 'rgb(255,176,32)'}} />} />
                
                    
                </div> */}

<Badge style={{marginTop:24}}>

<Typography style={{marginBottom:15}} variant='h5'>Müşteri Bilgileri</Typography>
<div style={{display:"flex", flex:1, alignItems:"center"}}>
                    <MuiTelInput forceCallingCode style={{width:"fit-content"}} defaultCountry='TR' value={this.state.phoneNumber} onChange={(e) => {
                        

                        if(e.length == 17) {
                            this.setState({phoneNumber: e, phoneNumberLoading: true, foundUserId: "i"})
                            this.searchPhoneNumber(e);
                        } else {
                            this.setState({phoneNumber: e, phoneNumberLoading: false, foundUserId: undefined})
                        }
                        console.log(e.length);
                    }} disableDropdown onlyCountries={['TR']} />
                    {this.state.phoneNumberLoading && <CircularProgress style={{marginLeft:10}} size={30} />}
                </div>

                    <div style={{display:"flex", flex:1, gap:15, marginTop:15}}>
                    <TextField disabled={this.state.foundUserId != undefined} value={this.state.clientName} onChange={(e) => this.setState({clientName: e.currentTarget.value})} label="Ad Soyad" style={{flex:1}} />
                    <TextField disabled={this.state.foundUserId != undefined} value={this.state.clientEmail} onChange={(e) => this.setState({clientEmail: e.currentTarget.value})} label="E-mail" style={{flex:1}} />
                    </div>

    </Badge>

<Badge style={{marginTop:24}}>
                <div style={{padding:0, display:"flex",flexDirection:"column", gap: 20}}>

                  
                {this.state.waypoints.map((x, index) => <Waypoint onRemove={() => {
                    this.state.waypoints.splice(index,1);
                    this.forceUpdate();
                }} waypoint={x} index={index} key={index} />)}
                <Button onClick={() => {
                    this.state.waypoints.push({elevator: 0});
                    this.forceUpdate();
                }}>Adres Ekle</Button>
                   


                <Typography style={{marginTop:30}} variant='h5'>Tarih ve Zaman</Typography>
                <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={this.state.order.date == 0?0:1}
      >
        <FormControlLabel onClick={() => {this.state.order.date = 0; this.forceUpdate()}} value={0} control={<Radio />} label="Hemen" />
        <FormControlLabel onClick={() => {this.state.order.date = Date.now(); this.forceUpdate()}} value={1} control={<Radio />} label="Planlanmış" />
        
      </RadioGroup>
                
                {this.state.order.date != 0 && <LocalizationProvider adapterLocale='tr' dateAdapter={AdapterDayjs}> <DateTimePicker disablePast ampm={false} defaultValue={dayjs(this.state.order.date)} onChange={(e) => this.state.order.date = e.$d.getTime()} /> </LocalizationProvider>}
               
                
                </div>

                    {this.state.calculating == false && <Button style={{marginTop:20}} onClick={() => {
                        this.setState({calculating: true});

                        API.fetch("calculate", {waypoints: this.state.waypoints, date: this.state.order.date}).then(e => {
                            console.log(e);
                            this.setState({calculating: false, routes: e.routes, additionalPrices: e.additionalPrices});
                           
                        })
                    }}>Hesapla</Button>}
                    {this.state.calculating && <div style={{display:"flex", flex:1, justifyContent:"center", alignItems:"center"}}><CircularProgress size={30} /></div>}
            </Badge>


            {this.state.routes != undefined && <div style={{display:"grid", gridTemplateColumns:"repeat(auto-fit,minmax(150px, 1fr))",flex:1, gap: 20, flexWrap:"wrap", marginTop:20}}>
                
                {this.state.routes.map((route, i) => {

                    if(i == 1 || i == 3) return <></>;


                    return <div key={i} onClick={() => {
                        this.state.order.vehicle = i;
                        this.state.order.additionals = [false,false,false,false,undefined]
                        this.forceUpdate();
                    }} style={{backgroundColor:"white",outline:this.state.order.vehicle == i?"2px solid #3C9CD7":undefined, cursor:"pointer",flexDirection:"column", display:"flex",flex:1, minWidth:100, aspectRatio:"1/1", justifyContent:"center", alignItems:"center",boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8}}>

                        <div style={{backgroundColor:"#eff1f5", width:"55%",height:"55%", position:"relative", display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"50%"}}>
                            <img src={vehicle_imgs[i]} style={{position:"absolute", width:"120%"}} />
                        </div>

                        <Typography style={{textDecoration:"line-through", color:"red"}}>{route.price}₺</Typography>
                        <Typography variant='h6'>{parseInt((.90)*route.price)}₺</Typography>

                    </div>
                    
                    /*<div style={{display:"flex", flex: 1,justifyContent:"center", alignItems:"center",backgroundColor: "#f6f8fb", borderRadius:10, aspectRatio: "1 / 1"}}>
                        <div style={{position:"absolute", backgroundColor:"#eff1f5", borderRadius:"50%", justifyContent:"center", alignItems:"center"}} >
                                <img style={{objectFit:"contain", width:"100%"}}  src={vehicle_imgs[i]} />

                        </div>
                    </div>*/

                })}    
                
            </div>}


            {this.state.order.vehicle != undefined && <Badge style={{marginTop:20}}>
                
                    {this.state.order.vehicle > 0 && <><Typography variant='h6'>Yardımcı Hizmeti</Typography>


                 
                    <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={this.state.order.additionals[4] == undefined?5:this.state.order.additionals[4]}

        style={{display:"flex", flexDirection:"column"}}
      >
        <FormControlLabel onClick={() => {this.state.order.additionals[4] = 0; this.forceUpdate()}} value={0} control={<Radio />} label="Eşyalarımı kendim taşıyacağım" />
        <FormControlLabel onClick={() => {this.state.order.additionals[4] = 1; this.forceUpdate()}} value={1} control={<Radio />} label={"Sürücünün yardımı lazım +" + this.getHelperPriceForCurrentVehicle(1) + "₺"} />
        <FormControlLabel onClick={() => {this.state.order.additionals[4] = 2; this.forceUpdate()}} value={2} control={<Radio />} label={"Sürücü ve 1 ekstra yardımcı lazım +" + this.getHelperPriceForCurrentVehicle(2) + "₺"} />
        <FormControlLabel onClick={() => {this.state.order.additionals[4] = 3; this.forceUpdate()}} value={3} control={<Radio />} label={"Sürücü ve 2 ekstra yardımcı lazım +" + this.getHelperPriceForCurrentVehicle(3) + "₺"} />
        {this.state.order.vehicle > 3 && <FormControlLabel onClick={() => {this.state.order.additionals[4] = 4; this.forceUpdate()}} value={4} control={<Radio />} label={"Sürücü ve 3 ekstra yardımcı lazım +" + this.getHelperPriceForCurrentVehicle(4) + "₺"} />}
      </RadioGroup></>}



      {this.state.order.vehicle > 1 && <>
        <Typography sx={{marginTop:3}} variant='h6'>Ekstra Hizmetler</Typography>
        <FormGroup style={{display:"flex", flexDirection:"column"}}>
            <FormControlLabel control={<Checkbox checked={this.state.order.additionals[0]} onChange={(e) => {this.state.order.additionals[0] = (e.target.checked || this.state.order.additionals[1]); this.forceUpdate() }} />} label="Ön koltukta refakatçi" />
            {this.state.order.vehicle == 2 && <FormControlLabel control={<Checkbox checked={this.state.order.additionals[1]} onChange={(e) => {this.state.order.additionals[1] = e.target.checked; if(e.target.checked)this.state.order.additionals[0] = true; this.forceUpdate() }} />} label="Evcil hayvan taşıma" />}
</FormGroup>
      
      </>}


      
      <TextareaAutosize value={this.state.order.note || ""} onChange={(e) => {this.state.order.note = e.currentTarget.value; this.forceUpdate()}} style={{padding:15, marginTop:20}} placeholder='Sürücüye not' />

                </Badge>}

               

           


           
                
                
          


            {this.state.order.vehicle != undefined && this.state.routes != undefined && <Badge style={{marginTop:15}}>
                
                <Typography  style={{marginBottom:24}} variant='h6'>Sipariş Detayları</Typography>

                {this.state.waypoints.map((x, i) => <Typography key={i}>{x.Label}</Typography>)}


                <Typography>Araç Seçimi: {VEHICLE_NAMES[this.state.order.vehicle]}</Typography>

                <Typography style={{marginTop:20}}>Servis Süresi: {minutesToString(this.state.routes[this.state.order.vehicle].time)}</Typography>

                <Typography>Baz Ücret: {parseInt((this.state.waypoints[0].city == 34?.9:.9)*this.state.routes[this.state.order.vehicle].price)}₺</Typography>

                {this.state.order.additionals[4] > 0 && <Typography>Yardımcı Ücreti: {this.getHelperPriceForCurrentVehicle(this.state.order.additionals[4])}₺</Typography>}

                <Typography variant='h6' style={{marginTop:20}}>Toplam Ücret: {(this.state.routes[this.state.order.vehicle].price-parseInt((this.state.waypoints[0].city == 34?.10:.10)*this.state.routes[this.state.order.vehicle].price)) + this.getHelperPriceForCurrentVehicle(this.state.order.additionals[4])}₺</Typography>
            </Badge>}




           
            <Button onClick={() => {

                if(this.state.routes == undefined) return alert("Hesaplama yapmanız gerekiyor");
                if(this.state.order.vehicle == undefined) return alert("Bir araç seçin");
                if(this.state.waypoints.find(s => s.Point == null || s.elevator == null) ) return alert("Adres detaylarını ve asansörü seçin");
                if(this.state.order.additionals[4] == undefined) return alert("Yardımcı seçimi yapın");
                if(this.state.phoneNumber.length != 17) return alert("Müşteri telefon numarası eksik/hatalı");
                if(this.state.clientName.length <= 2) return alert("Müşteri ad ve soyad eksik/hatalı");
                if(this.state.clientEmail.length <= 2) return alert("Müşteri email eksik/hatalı");

                global.openPrompt("Sipariş oluşturmak istediğinden emin misin?", "Sipariş oluşturduktan sonra müşterinin telefon numarasına bir sms gidicek, müşteri oradan siparişi onayla demesi gerekiyor!", "Onayla", () => {
                    global.showLoadingPanel();

                    API.fetch("createorder", {calculations: {...this.state.routes[this.state.order.vehicle]},price: (this.state.routes[this.state.order.vehicle].price-parseInt((this.state.waypoints[0].city == 34?.10:.10)*this.state.routes[this.state.order.vehicle].price)) + this.getHelperPriceForCurrentVehicle(this.state.order.additionals[4]),waypoints: this.state.waypoints, vehicle: this.state.order.vehicle, note: this.state.order.note, additionals: this.state.order.additionals,phone:this.state.phoneNumber.trim().replace(/ /g, ""),date: this.state.order.date, clientName: this.state.clientName, clientEmail: this.state.clientEmail}).then(e => {
                        
                        global.hideLoadingPanel();
                        if(e._id) {
                            
                        } else {
                            global.showError("Error");
                        }
                    })
                
                })
               
            }} style={{marginTop:20}}>Siparişi Oluştur ve Müşteriye Yolla</Button>

           

        


        </div>

        

        

      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.backdropVisible}
  
>
  <CircularProgress color="inherit" />
</Backdrop>
        </div>
    }



    getHelperPriceForCurrentVehicle(helperCount) {
        if(!helperCount) return 0;

        if(this.state.additionalPrices == undefined) return 0;
        return (this.state.additionalPrices[3][this.state.order.vehicle])+(Math.max(helperCount-1,0)*(this.state.additionalPrices[4][this.state.order.vehicle]))
    }
}






class Waypoint extends React.Component {

    constructor(props) {
        super(props);

        this.state = {open: false, loading: false, options: []};

        this.session = rnd(36);

        this.forceUp = this.forceUpdate;
    }
    render() {
        return <div style={{display:"flex", flex:1, flexDirection:"column", maxWidth:600, gap: 15}}>

            <div style={{display:"flex", alignItems:"center"}}><Typography variant='h5'>Adres {this.props.index}</Typography>{this.props.index > 1 && <Button onClick={() => this.props.onRemove()} color='error' style={{marginLeft:20}}>Kaldır</Button>}</div>


            <Autocomplete
        ref={a => this.bb = a}
        id="google-map-demo"
       
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : (option.Label || "")
        }
        filterOptions={(x) => x}
        options={this.state.options}
        isOptionEqualToValue={(a,b) => a.Label == b.Label}
        
        filterSelectedOptions
        defaultValue={this.props.waypoint || {Label: ""}}
        onChange={(event, value) => {
  
            this.currValue = value;
            if(value != undefined)
                Object.keys(value).forEach(s => this.props.waypoint[s] = value[s]);
            

            
            if(value != undefined) {
                API.fetchClient("autocompleteendsession", {session: this.session, _id: value.place}).then(e => {
                    if(this.currValue == value) {

                        this.props.waypoint.Point = e.Point;
                    }
                })

                this.session = rnd(36);
            } else {
                delete this.props.waypoint.Label;
                delete this.props.waypoint.Point;
                delete this.props.waypoint.allowed;
                delete this.props.waypoint.city;
                delete this.props.waypoint.place;
                delete this.props.waypoint.tooUnspecific;
            }
        }}
        onInputChange={(event, val) => {
            if(this.autocompleteQuery) {
                clearTimeout(this.autocompleteQuery);
                this.autocompleteQuery = null;
            }

            //var val = e.currentTarget.value;
            this.currVal =val;

            if(val.length <= 3) return;
            this.setState({loading: true});
            this.autocompleteQuery = setTimeout(() => {
                API.fetchClient("autocomplete", {q: val, session: this.session}).then(results => {
                 
                    if(this.currVal == val && Array.isArray(results)) {
                        
                        this.setState({options: results, loading: false});
                        console.log(results);
                        
                    }
                })
            }, 500);
        }}
        renderInput={(params) => (
          <TextField ref={(bb) => this.ba = bb}  {...params} label="Ara" fullWidth />
        )}
        
       
      />
            {/* <Autocomplete
                
                sx={{ }}
                open={this.state.open}
                onOpen={() => {
                    this.setState({open: true});
                }}
                onClose={() => {
                    this.setState({open: false});
                }}
                isOptionEqualToValue={(option, value) => option.Label === value.Label}
                getOptionLabel={(option) => option.Label || ""}
                options={this.state.options}
                onChange={(e, value) => {
                    this.currValue = value;
                    if(value != undefined)
                        Object.keys(value).forEach(s => this.props.waypoint[s] = value[s]);
                    

                    
                    if(value != undefined) {
                        API.fetchClient("autocompleteendsession", {session: this.session, _id: value.place}).then(e => {
                            if(this.currValue == value) {

                                this.props.waypoint.Point = e.Point;
                            }
                        })

                        this.session = rnd(36);
                    } else {
                        delete this.props.waypoint.Label;
                        delete this.props.waypoint.Point;
                        delete this.props.waypoint.allowed;
                        delete this.props.waypoint.city;
                        delete this.props.waypoint.place;
                        delete this.props.waypoint.tooUnspecific;
                    }
                }}
                loading={this.state.loading}
                
                renderInput={(params) => (
                    <TextField
                    {...params}
                    onChange={(e) => {
                        if(this.autocompleteQuery) {
                            clearTimeout(this.autocompleteQuery);
                            this.autocompleteQuery = null;
                        }
    
                        var val = e.currentTarget.value;

                        if(val.length < 3) return;
                        this.currVal =val;
    
                        this.setState({loading: true});
                        this.autocompleteQuery = setTimeout(() => {
                            API.fetchClient("autocomplete", {q: val, session: this.session}).then(results => {
                             
                                if(this.currVal == val) {
                                    
                                    this.setState({options: results, loading: false});
                                    console.log(results);
                                    
                                }
                            })
                        }, 500);
                    }}
                    />
                )}
                /> */}

                <div style={{display:"flex", flex:1, gap: 15}}>
                <TextField label="Bina" value={this.props.waypoint.building || ""} onChange={(e) => {this.props.waypoint.building = e.currentTarget.value; this.forceUpdate()}} sx={{display:"flex",flex:1}} /> 
                <TextField label="Kat" value={this.props.waypoint.floor || ""} onChange={(e) => {this.props.waypoint.floor = e.currentTarget.value; this.forceUpdate()}} sx={{display:"flex",flex:1}} /> 
                <TextField label="Daire" value={this.props.waypoint.door || ""} onChange={(e) => {this.props.waypoint.door = e.currentTarget.value; this.forceUpdate()}} sx={{display:"flex",flex:1}} /> 
                </div>


                <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={this.props.waypoint.elevator}
      >
        <FormControlLabel onClick={() => {this.props.waypoint.elevator = 0; this.forceUpdate()}} value={0} control={<Radio />} label="Taşınabilir" />
        <FormControlLabel onClick={() => {this.props.waypoint.elevator = 1; this.forceUpdate()}} value={1} control={<Radio />} label="Bazısı taşınabilir" />
        <FormControlLabel onClick={() => {this.props.waypoint.elevator = 2; this.forceUpdate()}} value={2} control={<Radio />} label="Taşınamaz" />
      </RadioGroup>
        </div>
    }
}


const rnd = (len, chars='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => [...Array(len)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('')

var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}

function minutesToString(seconds) {
    // Calculate minutes and remaining seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
  
    // Format the output string
    const formattedString = ((minutes > 0)?`${minutes} saat `:'')+(remainingSeconds > 0?`${remainingSeconds} dakika `:'');

    if(seconds <= 0) return "0 dakika";
  
    return formattedString;
  }


  
  