import JYSKLogo from '../components/JYSKLogo';


export default ({order}) => (
  <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
    <p
      style={{ color: order.kioskId != undefined ? "#143C8A" : undefined }}
    >
      {order.assignedWorker != undefined
        ? "(" + order.assignedWorker.name.substring(0, 1) + ") "
        : ""}
      #{order._id.toString().substring(18).toUpperCase()}
    </p>
    {order.kioskId != undefined && (
      <JYSKLogo style={{ aspectRatio: "31/14", height: 20 }} />
    )}
  </div>
);
