import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import API from '../API';
import LocationOnIcon from '@mui/icons-material/LocationOn';


/*
    SEARCH TYPES:

    drivers: true, 0
    clients: true, 1
    applications: true, 2
    orders: true 3

*/
export default class SearchBarComponent extends React.Component {

    constructor(props) {
        super(props);

       
        this.state = {searchValue: "", options: []}
    }

    setSearchValue(searchValue) {
      
      
this.setState({searchValue})
    }
    render() {
      this.ignoreTypes = [];
      if(this.props.search) {
          if(!this.props.search.drivers) this.ignoreTypes.push(0);
          if(!this.props.search.clients) this.ignoreTypes.push(1);
          if(!this.props.search.applications) this.ignoreTypes.push(2);
          if(!this.props.search.orders) this.ignoreTypes.push(3);
      }

        return <Autocomplete
        ref={a => this.bb = a}
        id="google-map-demo"
        sx={{ width: "100%" }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.label
        }
        filterOptions={(x) => x}
        options={this.state.options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.searchValue}
        inputValue={this.state.searchValue}
        isOptionEqualToValue={() => false}
        onChange={(event, newValue) => {
  
          this.props.onValueSelected(newValue);
          //window.location.href = types[newValue.type] + newValue._id;
        }}
        onInputChange={(event, newInputValue) => {
          this.setState({searchValue: newInputValue})
          if(this.searchTimeout) clearTimeout(this.searchTimeout);
  
          var currSearch = setTimeout(() => {
           
            API.fetch("search", {q: newInputValue, ignoreTypes: this.ignoreTypes}).then((e) => {
              if(currSearch != this.searchTimeout) return;
              
              e.forEach(s => s.key = Math.random());
              this.setState({options: e})
            })
          }, 100);
          this.searchTimeout = currSearch;
        }}
        renderInput={(params) => (
          <TextField ref={(bb) => this.ba = bb} autoFocus={this.props.dontFocus?false:true} {...params} label="Ara" fullWidth />
        )}
        renderOption={(props, option) => {
          // const matches = option.structured_formatting.main_text_matched_substrings;
          // const parts = parse(
          //   option.structured_formatting.main_text,
          //   matches.map((match) => [match.offset, match.offset + match.length]),
          // );
          var parts = [];
          
  
          return (
            <li {...props} key={option.key}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: 'text.secondary', mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.label}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    }
}

