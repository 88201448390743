import React from 'react';
import { Typography, Button, Avatar, Menu,TextField, Select, MenuItem,CircularProgress, Backdrop, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ErrorIcon from '@mui/icons-material/Error';
import { Document } from 'react-pdf';
import WhatsappIcon from '../whatsapp.png';
import ForumIcon from '@mui/icons-material/Forum';
import OrderIDTableCell from '../components/OrderIDTableCell';
// const FILES = ["Sürücü Belgesi Ön", "Sürücü Belgesi Arka", "İkametgah Belgesi", "Adli Sicil Kaydı", "Profil Fotoğrafı"];
const VEHICLE_NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet","Esnek"];

var IN_EDIT_MODE = false;

var STATUS = {};
STATUS[0] = {
    t: "Sürücü Bekliyor",
    color: "rgb(80,72,229)"
}
STATUS[1] = {
    t: "Planlanmış",
    color: "rgb(80,72,229)"
}
STATUS[2] = {
    t: "Aktif Sipariş",
    color: "orange"
}
STATUS[3] = {
    t: "Tarih Kesinleş.",
    color: "rgb(80,72,229)"
}
STATUS[10] = {
    t: "Tamamlanmış",
    color: "green"
}
STATUS[11] = {
    t: "Müşteri İptal",
    color: "yellow"
}
STATUS[-1] = STATUS[11];
STATUS[12] = {
    t: "Sürücü İptali",
    color:"crimson"
}
export default class ClientScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: true,editing: false, saving: false, approveDriverDialog: false, backdropVisible: false};

        
        var id = props.match.params.id;

        
        if(!id) props.history.goBack();

        
    }

    componentDidMount() {
        var id = this.props.match.params.id;
        
        API.fetch("getclient", {_id: id}).then((e) => {


            this.setState({client: e, loading: false});
        })
        // API.fetch('getapplications').then((e) => {
        //     if(!e.error) this.setState({applications: e});
        // })
    }
    render() {
        IN_EDIT_MODE = this.state.editing;

        if(this.state.loading) return <></>
        return <div style={{display:"flex", justifyContent:"center"}}>

            <div style={{maxWidth:900, width:"100%"}}>
            <div style={{marginTop:40, display:"flex", justifyContent:"space-between", alignItems:"center",flexWrap:"wrap",gap: 10}}>
                <div style={{display:"flex"}}>
                    <Avatar sx={{width:70, height:70}}  />
                    <div style={{marginLeft:20}}>
                        <Typography variant='h4'>{this.state.client.name + " " + API.getPlatformEmoji(this.state.client.platform)}</Typography>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <Typography variant='body2'>client_id: </Typography>
                            <div style={{marginLeft:10, backgroundColor:"#E5E7EB", borderRadius:50, paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2}}>
                                <Typography style={{fontWeight:"600"}} variant='body2'>{this.state.client._id}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:"flex", gap:15}}>

                <a href="#" style={{fill: "#3c9cd7"}} onClick={() => {
            window.open('../livesupport/'+this.state.client._id,'popup','width=400,height=600')
        }}><ForumIcon  htmlColor={'#3c9cd7'} /></a>

                <a href={"https://wa.me/"+(this.state.client.phone)} target={'_blank'}><img style={{width:25,height:25,marginRight:18}} src={WhatsappIcon} /></a>
                    {/* {!this.state.editing && <Button onClick={() => {

                        this.driverOld = JSON.parse(JSON.stringify(this.state.driver));
                        this.setState({editing: true});
                    }} style={{marginRight:20}} variant='outlined'>Düzenle <EditIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}
                    {this.state.editing && <Button onClick={() => {

                        
                        this.setState({editing: false, driver: this.driverOld});
                    }} style={{marginRight:20}} color='error' variant='outlined' >İptal <CloseIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>} */}
                    {/* {!this.state.editing && <Button onClick={() => this.setState({approveDriverDialog: true})} variant='contained'>Başvuruyu Onayla</Button>} */}
                </div>

                
            </div>

                {/* <div style={{display:"flex",marginTop:24}}>
                    <LogisticAnalytic backgroundColor={'rgba(80,72,229,.1)'} first title={"Son 7 günde sipariş"} value={this.state.driver.orders.length} icon={<LocalShippingIcon sx={{color: 'rgb(80,72,229)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(134,197,82,.1)"} title={"Güncel bakiye"} value={(this.state.driver.credits+this.state.driver.freeCredits) +(this.state.driver.freeCredits > 0?" ("+this.state.driver.credits+")":"")} icon={<AccountBalanceWalletIcon sx={{color: 'rgb(134,197,82)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(255,176,32,.1)"} title={"7 günde iptal"} value={(this.state.driver.canceledOrderCount)} icon={<ErrorIcon sx={{color: 'rgb(255,176,32)'}} />} />
                
                    
                </div> */}

<Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:0}}>
                <Typography style={{marginBottom:20, marginLeft:24, marginTop:24}} variant='h6'>Detaylar</Typography>
                
                
                <DetailText first label={"Email"} onChange={(e) => {this.state.client.email = e; this.forceUpdate()}} value={this.state.client.email} />
                <DetailText onCopy={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(document.getSelection().toString().replace(/ /g, ''));

                }} label={"Telefon"} onChange={(e) => {this.state.client.phone = e; this.forceUpdate()}} value={`+90 ${this.state.client.phone.slice(3, 6)} ${this.state.client.phone.slice(6, 9)} ${this.state.client.phone.slice(9)}`} />
                <DetailText editable={false} label={"Kayıt Tarihi"} value={dateFromObjectId(this.state.client._id).toLocaleString("tr-TR")} />
                {this.state.client.business != undefined && <DetailText label={"Kurumsal Hesap"} value={this.state.client.business.name} />}
                </div>
            
            </Badge>


            

            


            <Badge style={{marginTop:24,padding:0,width:"100%"}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Son Siparişler</Typography>
                
                </div>
                <div style={{width:"100%", height:30, minWidth: 0}}>

<DataGrid
                autoHeight
                style={{width:"100%"}}
                rows={this.state.client.orders.map((x,i) => ({id:i,order:x,Tarih: dateFromObjectId(x._id).toLocaleString("tr-TR"),Araç: VEHICLE_NAMES[x.vehicle],Durum: x.status,ID: x._id.substring(18).toUpperCase()}))}
                columns={[{field: "ID",renderCell: (params) => <OrderIDTableCell order={params.row.order} />,flex: 1}, {field: "Sürücü",flex: 1,renderCell: (params) => params.row.order.driver?<RouterLink to={'/driver/'+params.row.order.driver}><Link>{params.row.order.driver.substring(18).toUpperCase()}</Link></RouterLink>:<p>-</p>},{field: "Tarih",flex: 1},{field:"Araç", flex:1}, {field: "Durum",flex: 1, renderCell: (params) => <div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10,borderRadius:20, backgroundColor:STATUS[params.value].color}}>
                    <Typography sx={{color:"white",fontWeight:"700"}} variant='body2'>{STATUS[params.value].t}</Typography>
                </div>},{field: " ", renderCell: (params) => {
                    return <RouterLink to={'/order/' + params.row.order._id} ><Button><ArrowForwardIcon /></Button></RouterLink>
                }}]}
                pageSize={10}
                rowsPerPageOptions={[10]}
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    minWidth:0
                    
                }}}
            />
                </div>
            
            </Badge>

            {/* {IN_EDIT_MODE && <div style={{display:"flex", justifyContent:"flex-end", marginTop:24, alignItems:"center"}}>
                
                {!this.state.saving && <Button onClick={() => {
                    this.setState({saving: true});

                    
                    API.fetch("savedriver", this.state.driver).then((e) => {
                        this.setState({saving: false, editing: false});
                    })
                }} variant='contained' color='success' size='medium'>Kaydet</Button>}
                {this.state.saving && <CircularProgress />}
            </div>} */}




            
        </div>

        

        

      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.backdropVisible}
  
>
  <CircularProgress color="inherit" />
</Backdrop>
        </div>
    }
}



// const DetailText = (props) => {
//     return <div style={{position:"relative",display:"flex", paddingTop:10, paddingBottom:10,alignItems:"center", borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
//         {/* <Typography  onCopy={(a) => console.log(a)} >{props.label}</Typography> */}
//         <p  style={{fontWeight:"600", marginLeft:24,content: props.valueData}} onCopy={(a) => {

//         }}>{props.label}</p>

//         {(!IN_EDIT_MODE || props.editable == false) && <Typography style={{position:"absolute", left:"20%"}}>{props.value}</Typography>}
//         {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
//         {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()}
//     </div>
// }

const DetailText = (props) => {
    return <div  style={{position:"relative",flexDirection:"column",display:"flex",paddingLeft:24, paddingTop:10, paddingBottom:10, borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
        
<div style={{display:"flex",flex:1}}>
        <div style={{maxWidth:180,flex:.6, display:"flex", alignItems:(props.centerLabel)?"center":undefined}}>
        <Typography style={{fontWeight:"600"}}>{props.label}</Typography>
        </div>
        <div className='detailParent' style={{flex:1, alignItems:"center", justifyContent:"center"}}>
        {(!IN_EDIT_MODE || props.editable == false) && props.link == undefined && <p onCopy={(e) => {
            if(props.onCopy)
                props.onCopy(e);
        }} {...props}>{props.value}</p>}
        {(!IN_EDIT_MODE || props.editable == false) && props.link != undefined && <Link to={props.link} ><Typography {...props}>{props.value}</Typography></Link>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)}  sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()}
        {!IN_EDIT_MODE && props.children && {...props.children}}
        </div>
        {/* 


        {(!IN_EDIT_MODE || props.editable == false) && <Typography {...props} style={{position:"absolute", left:window.innerWidth < 500?"40%":"20%"}}>{props.value}</Typography>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()} */}

</div>

        {props.altComponent != undefined && props.altComponent}
    </div>
}

var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}