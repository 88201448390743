import React from 'react';
import { Typography, Button, Avatar,  } from '@mui/material';
import { DataGrid, gridNumberComparator } from '@mui/x-data-grid';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
const NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan","Panelvan","Kamyonet"];

const shortcutsItems = [
    {
        label: 'Bugün',
        getValue: () => {
          const today = dayjs();
          return [today.startOf('day'), today.endOf('day')];
        },
      },
    {
      label: 'Bu Hafta',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Geçen Hafta',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Son 7 Gün',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Bu Ay',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    { label: 'Her zaman', getValue: () => [null, null] },
  ];

export default class ApplicationsScreen extends React.Component {

    constructor() {
        super();
        this.state = {rows: []}
    }

    componentDidMount() {
        API.fetch('getapplications').then((e) => {

            
            if(!e.error) {
                this.allRows = e.map((x, i) => {return {id: i, "AD SOYAD": x, "TARİH": dateFromObjectId(x._id), dateString: dateFromObjectId(x._id).toLocaleString('tr-TR'),"TAMAMLAMA TARİHİ": x.submitDate && x.status == 1?x.submitDate:0, completeDate: x.submitDate && x.status == 1?new Date(x.submitDate).toLocaleString('tr-TR'):'', "DURUM": x.status, "NOT": x.note, "TELEFON": x.tel,"ŞEHİR": API.getCityName(x.city),tFile: x.tFile, lFile: x.lFile, "ARAÇ": NAMES[x.type]}});
                this.setState({rows: this.allRows});
            }
        })
    }


    refreshCurrentFilters() {
        console.log(this._idFilter, this.dateFilter);

        this.setState({rows: this.allRows.filter( s=> {
            if(this._idFilter && (s["TARİH"] < this._idFilter.$gte || s["TARİH"] > this._idFilter.$lte)) return false;
            if(this.dateFilter && (s["TAMAMLAMA TARİHİ"] < this.dateFilter.$gte || s["TAMAMLAMA TARİHİ"] > this.dateFilter.$lte)) return false;

            return true;
        })})
    }
    render() {
        return <div>

<div style={{display:"flex", gap: 10, justifyContent:"flex-end"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div>
                <p>Oluşturma Tarihi</p>
                <DateRangePicker onAccept={(range) => {
                   

                    if((range[0] == null && range[1] == null) || (range[0] != null && range[1] != null)) {

                        if(range[0] != null) {
                        var firstRange = new Date(range[0].$d);

                        firstRange.setHours(0,0,0);
        
                        var secRange = new Date(range[1].$d);
                        secRange.setHours(23,59,59);
                        
                        this._idFilter = {$gte: firstRange.getTime(),$lte: secRange.getTime()}
                        } else {
                            this._idFilter = null;
                        }

                        this.refreshCurrentFilters();
                    }

                 


                }} slotProps={{shortcuts: {items: shortcutsItems}, actionBar: {actions: []}}} slots={{field: SingleInputDateRangeField}} localeText={{ start: 'Check-in', end: 'Check-out' }} />
                </div>

                <div>
                <p>Tamamlama Tarihi</p>
                <DateRangePicker onAccept={(range) => {
                   

                    if((range[0] == null && range[1] == null) || (range[0] != null && range[1] != null)) {

                        if(range[0] != null) {
                        var firstRange = new Date(range[0].$d);

                        firstRange.setHours(0,0,0);
        
                        var secRange = new Date(range[1].$d);
                        secRange.setHours(23,59,59);
                        
                        this.dateFilter = {$gte: firstRange.getTime(),$lt: secRange.getTime()}
                        } else {
                            this.dateFilter = null;
                        }
                        this.refreshCurrentFilters();
                    }

                 


                }} slotProps={{shortcuts: {items: shortcutsItems}, actionBar: {actions: []}}} slots={{field: SingleInputDateRangeField}} localeText={{ start: 'Check-in', end: 'Check-out' }} />
                </div>
            </LocalizationProvider>
            </div>
<Badge style={{marginTop:50,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Başvurular</Typography>
                
                </div>
            <DataGrid
                
                disableSelectionOnClick={true}
                autoHeight
                sortingOrder={['desc', 'asc']}
                initialState={{
                    sorting: {
                        sortModel: [{field: "TARİH", sort:"asc"}]
                    }
                }}
                rows={this.state.rows}
                columns={[{field: "AD SOYAD",flex:1, renderCell: (params) => {
                    
                    return <div style={{display:"flex",alignItems:"center"}}>
                        {/* <Avatar src={""+params.value.profileURL+""}  /> */}
                        
                        <div style={{marginLeft:0}}>
                            <Typography variant="body1">{params.value.firstName + " " + params.value.lastName}</Typography>
                            <Typography variant="body2">{params.value.email}</Typography>
                        </div>
                    </div>
                }}, {field: "TARİH",flex:1,renderCell: (params) => params.row.dateString, sortComparator: (v1, v2) => v1-v2}, {field: "TAMAMLAMA TARİHİ",flex:1,renderCell: (params) => params.row.completeDate, sortComparator: (v1, v2) => v1-v2} , {field: "DURUM",flex:1, renderCell: (params) => {
                    
                    return <div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10,borderRadius:20,width:"fit-content",height:"fit-content", backgroundColor:(params.value ==0)?"orange":"green"}}>
                    <Typography sx={{color:"white",fontWeight:"700"}} variant='body2'>{(params.value == 0?"BAŞVURU YAPILIYOR":"ONAY BEKLİYOR") + " (" + params.row.lFile + "/" + params.row.tFile + ")"}</Typography>
                </div>
                }},{field:"NOT", flex:1}, {field: "TELEFON",flex:1},{field: "ŞEHİR",flex:1},{field:"ARAÇ", flex:1},{field: " ", renderCell: (params) => {
                    return <a href={'../application/'+ params.row['AD SOYAD']._id}><Button><ArrowForwardIcon /></Button></a>
                    // return <Link to={'/application/' + params.row['AD SOYAD']._id} ><Button><ArrowForwardIcon /></Button></Link>
                }}]}
                pageSize={50}
                rowsPerPageOptions={[50]}
                slotProps={{pagination: {showFirstButton: true, showLastButton: true}}}
                
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge>
        </div>
    }
}

var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}